import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
//for validation
import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Link, useHistory, Redirect } from "react-router-dom";
import { Container, Button, Form, FormGroup, Label, Row, Col } from "reactstrap";
import { showAlert } from "../util/alertModal";
import { Header, Footer } from "../common";
import "../translations/i18n";
import { useTranslation } from "react-i18next";
import PageTitle from "../common/PageTitle";

import "react-international-phone/style.css";
import CommonPhoneInput from "../util/CommonPhoneInput";
import ReCAPTCHA from 'react-google-recaptcha';
import { sentContact } from "../services/public.service";
const SignUpContactPage = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const { isLoggedIn, language } = useSelector((state) => state.auth);
	const [phoneNumber, setPhoneNumber] = useState();

	const [isRecaptchaValidate, setIsRecaptchaValidate] = useState(false);
	const [recaptchaValue, setRecaptchaValue] = useState();
	const reCaptcha = useRef();
	//form validation scheme
	const registrationValidationSchema = Yup.object().shape({
		name: Yup.string().required(t("Name is required")),
		email: Yup.string().required(t("Email is required")).email(t("Email is invalid")),
		phone: Yup.string().required("Phone number is required"),
		message: Yup.string().required(t("Message is required")),
	});
	//use form hook
	const {
		register,
		handleSubmit,
		reset,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(registrationValidationSchema),
	});

	if (isLoggedIn) {
		return <Redirect to={`/${language}/dashboard`} />;
	}
	const validateCaptcha = async (value) => {
		if (value) {
			//success
			setRecaptchaValue(value);
			setIsRecaptchaValidate(true);
		} else {
			reCaptcha.current.reset();
			setRecaptchaValue('');
			setIsRecaptchaValidate(false);
			showAlert(t('Recaptcha validation failed'));
		}
	};
	const registerEvent = async (data) => {
		let authPayload = {
			name: data.name,
			phone_no: data.phone_no?.replace(/\D/g, ""), // remove all non-digit characters from phone number
			email: data.email,
			message: data.message,
			recaptchaValue,
			language,
			type: 'sign_up'
		};

		try {
			setIsButtonLoading(true);
			let isSentContact = await sentContact(authPayload);

			setIsButtonLoading(false);
			if (isSentContact) {
				reset();
				//clear phone number
				setPhoneNumber('');
				showAlert(t('Sign up request sent successfully! We will contact you soon.'));
				reCaptcha.current.reset(); // after send mail rest the captcha
			}
		} catch (err) {
			setIsButtonLoading(false);
			showAlert(t(err), true);
		}
	};

	// phone number validation custom function
	const validatePhoneNumber = (value, dialCode) => {
		// get value with out country code
		const withOutStdCode = value?.replace(`+${dialCode}`, "");
		setPhoneNumber(value);
		if (withOutStdCode.length === 0) {
			setValue("phone_no", "");
			errors.phone_no = true;
		} else {
			setValue("phone_no", withOutStdCode);
			errors.phone_no = false;
		}
	};
	return (
		<>
			<PageTitle title={t("Sign up")} description="Aninvoice sign up page" url="" />
			<Header />
			<main className="logbody reg position-relative">
				<span className="colbox1"></span>
				<span className="colbox2"></span>
				<Container>
					<div className="logregfrmBox">
						<section>
							<div className="frmbox position-relative">
								<h1 className="m-0">
									<span>{t("Sign up")}</span>
								</h1>
								<Form onSubmit={handleSubmit(registerEvent)}>
									<Row className="p-0">
										<Col xs="12">
											{/* col-sm-12 */}
											<FormGroup>
												<Label for="firstName">
													{t("Your name")}
													<i>*</i>
												</Label>
												<input
													type="text"
													name="name"
													id="name"
													placeholder={t("Your name")}
													{...register("name")}
													className={`form-control ${errors.name ? "is-invalid" : ""}`}
												/>
												<div className="invalid-feedback">{errors.name?.message}</div>
											</FormGroup>
										</Col>

									</Row>

									<FormGroup>
										<Label for="exampleEmail">
											{t("Email")}
											<i>*</i>
										</Label>
										<input
											type="email"
											name="email"
											id="exampleEmail"
											placeholder={t("Email")}
											{...register("email")}
											className={`form-control ${errors.email ? "is-invalid" : ""}`}
										/>
										<div className="invalid-feedback">{errors.email?.message}</div>
									</FormGroup>
									<FormGroup>
										<Label for="phone_no">
											{t("Phone number")}
											<i>*</i>
										</Label>
										<CommonPhoneInput
											value={phoneNumber}
											onChange={(value, country) => {
												const dialCode = country?.country?.dialCode; // get the dial code
												validatePhoneNumber(value, dialCode);
											}}
											register={register}
											fieldName="phone"
										/>

										<div className="invalid-feedback">{errors.phone_no?.message}</div>
									</FormGroup>

									<FormGroup>
										<Label for="message">
											{t("Message")}
											<i>*</i>
										</Label>
										<textarea
											style={{ height: "100px" }}
											name="message"
											id="message"
											placeholder={t("message")}
											{...register("message")}
											className={`form-control ${errors.message ? "is-invalid" : ""}`}
										/>
										<div className="invalid-feedback">{errors.message?.message}</div>
									</FormGroup>
									<div className="mb-3">
										<ReCAPTCHA
											ref={reCaptcha}
											sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
											onChange={validateCaptcha}
											render="explicit"
											hl={language}
										/>
									</div>
									<FormGroup>
										<Button color="primary" block className={`thm2 ${isButtonLoading ? "loading" : ""}`} disabled={!isRecaptchaValidate || isButtonLoading}>
											{t("Sign up")}
										</Button>
									</FormGroup>
								</Form>
								<div className="signalter text-center">
									<p>{t("I have an account?")} </p>
									<div>
										<Link className="btn btn-outline-primary btn-block" to={`/${language}/login`}>
											{t("Sign in")}
										</Link>
									</div>
								</div>
							</div>
						</section>
					</div>
				</Container>
			</main>
			<Footer />
		</>
	);
};

export default SignUpContactPage;
