import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import './../../translations/i18n';
import { useTranslation } from 'react-i18next';

const OrganizationDetails = (props) => {
  const { t } = useTranslation();
  const language = useSelector((state) => state.auth.language);
  // console.log(props);
  return (
    <div className="info p-2">
      <p className="nm">
        <b style={{lineHeight:"1.2"}}>
          {props?.organization?.organization_id?.organization_name?.[language]}
        </b>
        {/* <span className='free p-2'>
					<b>Free</b>
				</span> */}
      </p>
      <p className="fadeletter">
        {t('Organization created on')}{' '}
        <span>{moment(props.organization?.organization_id?.createdAt).format(
          'DD/MM/YYYY'
        )}</span>
      </p>
      <p className="fadeletter">
        {t('Organization Id')} :
        <b title={props.organization?.organization_id?._id}>{props.organization?.organization_id?._id}</b>
      </p>
      {props.organization?.organization_id?.features_config?.is_inventory_enabled && (
        <p className='fadeletter'>
          {t('Inventory')} :<b>{t('Enable')}</b>
        </p>
      )}
      {/*<p className='fadeletter'>
				Edition :<b>India</b>
			</p>
			<p className='fadeletter'>
				<b>You are an admin in this Organization</b>
			</p> */}
    </div>
  );
};
export default OrganizationDetails;
