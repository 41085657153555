import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//for validation
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import moment from "moment";
import Select from "react-select";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { createNewOrganization, getAnOrganization, updateAOrganization, patchOrganization } from "../../services/organizatiom.service";
import { UPDATE_ORGANIZATION } from "../../store/actions/types";
import { showAlert } from "../../util/alertModal";
import "./../../translations/i18n";
import { useTranslation } from "react-i18next";

import {
	// COUNTRYLIST,
	isEmpty,
	OrganizationsLogoType,
	OrganizationsLogoSize,
	THEMELIST,
	PREFERRED_LANGUAGE,
	// BUYER_ID_LABEL_ARRAY,
	INDUSTRY_SECTOR,
	showAlertNotification,

} from "../../util/helper";
// import {
//   FileUploadService,
//   getImageDisplayLink,
// } from '../../util/FileUploadService';
import * as Icon from "react-bootstrap-icons";
import alertify from "alertifyjs";
import { Loading } from "./../../common";
import { uploads } from "../../util/uploadFilesExpress";
import { getCountryCurrencies } from "../../services/country_currency.service";
import CommonPhoneInput from "../../util/CommonPhoneInput";
import ValidationErrorMessage from "../shared/ValidationErrorMessage";
// import Autosuggest from "react-autosuggest";

const AddOrganizationForm = (props) => {
	// let page_name = window.location.pathname.split('/')[2];
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const { id } = useParams();
	const [loadingItems, setLoadingItems] = useState(false);
	const [organizationData, setOrganizationData] = useState();
	//let userInfo = JSON.parse(localStorage.getItem('user_info'));
	const [isButtonLoading, setIsButtonLoading] = useState(false);
	const { userInfo, language } = useSelector((state) => state.auth);

	const [arabicName, setArabicName] = useState();
	const [englishName, setEnglishName] = useState();
	const [isArtype, setIsArtype] = useState(false);
	const [isEntype, setIsEntype] = useState(false);
	// const [currency, setCurrency] = useState();
	const [country, setCountry] = useState("SA"); //default country saudi arabia
	// const [city, setCity] = useState();

	const [fileDelete, setFileDelete] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [organizationlogo, setOrganizationlogo] = useState(null);
	const [theme, setThemeClass] = useState(); //default theme
	const [themeName, setThemeName] = useState(); //default theme
	const [themeSlug, setThemeSlug] = useState(); //default theme
	const [prefferedLanguage, setPrefferedLanguage] = useState("b");
	const [langPrevValue, setLangPrevValue] = useState();
	const [fiscalYearStartMonth, setFiscalYearStartMonth] = useState(0); //default "January - December"
	const organization_id = useSelector((state) => state?.auth?.userInfo?.default_organization_id?._id || "");
	const current_organization_id = useSelector((state) => state.auth.userInfo.default_organization_id?._id);
	const [taxTreatMent, setTaxTreatment] = useState("Y");
	const [CURRENCYLIST, setCURRENCYLIST] = useState([]);
	const [currencyCodeOption, setCurrencyCodeOption] = useState([]);
	const [countryCodeOption, setCountryCodeOption] = useState([]);

	const [validationErrors, setValidationErrors] = useState([]);
	const [sectorSate, setSectorSate] = useState("");
	const [industry_sector_value, SetIndustry_sector_value] = useState();

	// new code check if user is verified or not then redirect to otp verification page
	// if (!userInfo.phone_verified) {
	// 	history.push(`/${language}/otp-verification`);
	// }
	if (!userInfo.email_verified) {
		history.push(`/${language}/otp-verification`);
	}

	//Fetch unique cuurency from db
	/**
	 *
	 * @param {*} searchParam if pass currency=1 then return currency list otherwise return country list
	 */
	const getAllCurrencies = async (searchParam = "") => {
		try {
			let country_currency_list = await getCountryCurrencies(searchParam);
			setCURRENCYLIST(country_currency_list.data);
			//build option list for react select
			let currencies = country_currency_list.data.map((cc) => ({ value: cc.code, label: cc.code + " - " + cc?.[language] }));
			setCurrencyCodeOption(currencies);
		} catch (err) { }
	};
	//Fetch unique country from db
	/**
	 *
	 * @param {*} searchParam if pass currency=1 then return currency list otherwise return country list
	 */
	const getAllCountryList = async (searchParam = "") => {
		try {
			let country_list = await getCountryCurrencies(searchParam);
			//build option list for react select
			let countries = country_list.data.map((cc) => ({ value: cc.iso2, label: cc?.[language] }));
			setCountryCodeOption(countries);
		} catch (err) { }
	};
	useEffect(() => {
		let searchParam = `&currency=1`;
		getAllCurrencies(searchParam);
		getAllCountryList();
	}, []);

	//only own organization can be edited
	useEffect(() => {
		if (id && id !== current_organization_id) {
			//redirect to 401 page unAuthorized
			history.push(`/${language}/401`);
		}
	}, [id]);

	//support file format
	const validateFile = (file) => {
		let val = 0;
		if (!OrganizationsLogoType?.includes(file?.type)) {
			val = 1;
		} else if (file?.size > OrganizationsLogoSize) {
			val = 2;
		}
		return val;
	};
	//copy Arabic name to English on change when add new category
	const copyArabicToEnglishHandler = (event) => {
		if (!id) {
			setIsArtype(true);
			setValue("organizationNameAr", event.target.value);
			errors.organizationNameAr = "";
			if (!isEntype) {
				setEnglishName(event.target.value);
				setValue("organizationNameEn", event.target.value);
				errors.organizationNameEn = "";
			}
		} else {
			setArabicName(event.target.value);
			setValue("organizationNameAr", event.target.value);
			errors.organizationNameAr = "";
		}
	};
	//copy English name to Arabic on change when add a new item
	const copyEnglishToArabicHandler = (event) => {
		if (!id) {
			setIsEntype(true);
			setValue("organizationNameEn", event.target.value);
			errors.organizationNameEn = "";
			if (!isArtype) {
				setArabicName(event.target.value);
				setValue("organizationNameAr", event.target.value);
				errors.organizationNameAr = "";
			}
		} else {
			setEnglishName(event.target.value);
			setValue("organizationNameEn", event.target.value);
			errors.organizationNameEn = "";
		}
	};
	// console.log(CURRENCYLIST);
	//form validation scheme

	let validate_for_country = ["SA"]; //list of countries that will required validation
	let do_validate = (value) => {
		return validate_for_country.includes(value);
	};

	const organizationValidationSchema = Yup.object().shape({
		organizationNameEn: "en" === language && Yup.string().trim().max(100, t("Maximum 100 characters")).required(t("Please enter English name")),
		organizationNameAr: "ar" === language && Yup.string().trim().max(100, t("Maximum 100 characters")).required(t("Please enter Arabic name")),
		// organization_address: Yup.string().required(t('Please enter your address')),
		//vat id validation only for saudi arabia country
		vatid:
			country === "SA" &&
			taxTreatMent === "Y" &&
			Yup.string()
				.required(t("Please enter the TRN number"))
				.min(15, t("VAT number must be 15 digits"))
				.matches(/^[3](.{13}[3])?$/, "It should start and end with 3, and 15 digits long")
				.matches(/^\d+$/, "The field should have digits only"),
		phone_number: Yup.string().trim().required(t("Please enter a mobile number")),
		smlink: Yup.string().nullable(true),
		industry_or_sector: Yup.string().required(t("Please choose industry or sector")),
	});

	//extend address validation only when adding a new organization
	const organizationValidationSchemaWithAddress = organizationValidationSchema.shape(
		{

			street_1: Yup.string().when("$country_code", {
				is: do_validate,
				then: Yup.string().trim().required(t("Please enter street 1")).typeError("Please enter street 1"),
				otherwise: Yup.string().nullable(true),
			}),
			street_2: Yup.string().nullable(true),
			district: Yup.string().when("$country_code", {
				is: do_validate,
				then: Yup.string().trim().required(t("Please enter district")).typeError("Please enter district"),
				otherwise: Yup.string().nullable(true),
			}),
			building_no: Yup.string().when("$country_code", {
				is: do_validate,
				then: Yup.string()
					.required(t("Please enter the building number"))
					.min(4, t("Building number must be exactly 4 digits"))
					.max(4, t("Building number must be exactly 4 digits"))
					.matches(/^\d+$/, t("The field should have digits only"))
					.typeError("Please enter the building number"),
				otherwise: Yup.string().nullable(true),
			}),
			additional_no: Yup.string().when("additional_no", {
				is: (value) => value !== "",
				then: Yup.string()
					.matches(/^([0-9]*)$/, t("Additional number is not valid"))
					.min(4, t("Additional number must be exactly 4 digits"))
					.max(4, t("Additional number must be exactly 4 digits"))
					.typeError(t("Additional number is not valid")),
				otherwise: Yup.string().trim().nullable(true),
			}),
			state: Yup.string().trim().nullable(true),
			city: Yup.string().when("$country_code", {
				is: do_validate,
				then: Yup.string().trim().required(t("Please enter city")).typeError("Please enter city"),
				otherwise: Yup.string().trim().nullable(true),
			}),

			zipcode: Yup.string().when("$country_code", {
				is: do_validate,
				then: Yup.string()
					.matches(/^\d+$/, t("Postal code is invalid"))
					.min(5, t("Postal code must be exactly 5 digits"))
					.max(5, t("Postal code must be exactly 5 digits"))
					.required(t("Please enter the postal code"))
					.typeError(t("Postal code is invalid")),
				otherwise: Yup.string().nullable(true),
			}),
		},
		["additional_no", "additional_no"]
	);

	//use form hook
	const {
		register,
		handleSubmit,
		setValue,
		control,
		formState: { errors },
	} = useForm({
		context: {
			country_code: country, //pass external value to use inside Schema using context
		},
		defaultValues: {
			currency: "SAR",
			country: "SA",
		},
		resolver: yupResolver(id ? organizationValidationSchema : organizationValidationSchemaWithAddress),
	});

	//populate
	const populateFieldWithData = async () => {
		if (id) {
			setLoadingItems(true);
			try {
				let organization_res = await getAnOrganization(id);

				if (organization_res) {
					setOrganizationData(organization_res);
					// setCountry(organization_res?.country);
					setValue("country", organization_res?.country); //for react select country
					// setCurrency(organization_res?.currency);
					setValue("currency", organization_res?.currency); //for react select

					// setCity(organization_res?.city);
					setPrefferedLanguage(organization_res?.preferred_language || "b"); //for default value on edit
					setLangPrevValue(organization_res?.preferred_language || "b"); //for class remove and set
					setFiscalYearStartMonth(organization_res?.fiscal_year_start_month ?? 0);
				}
				setEnglishName(organization_res?.organization_name?.en);
				setArabicName(organization_res?.organization_name?.ar);

				//value set on edit validation

				setValue("organizationNameEn", organization_res?.organization_name?.en);
				setValue("organizationNameAr", organization_res?.organization_name?.ar);
				setValue("vatid", organization_res?.vat_id);
				setValue("smlink", organization_res?.website);
				// setValue('organization_address', organization_res?.organization_address);
				setValue("city", organization_res?.city);
				setValue("companyid", organization_res?.company_id);
				setValue("phone_number", organization_res?.phone_number);
				//set industry or sector data

				if (organization_res?.vat_id === "" || organization_res?.vat_id === "") {
					setTaxTreatment("N");
				}
				updateTheme(organization_res?.theme || 1); //common function for theme state update
				// setLangPrevValue(organization_res?.preffered_language || 'b'); //common function for language state update
				if (!isEmpty(organization_res?.organization_logo)) {
					let imageUrl = `${process.env.REACT_APP_CDN_DOMAIN}/${organization_res?.organization_logo[0]?.upload_path}`;
					setOrganizationlogo(imageUrl);
				}
				//industry or sector set
				setSectorSate(organization_res?.industry_or_sector);
				const editIndustry_sector = industry_sector.find((industry) => industry?.value === organization_res?.industry_or_sector);
				SetIndustry_sector_value({
					label: editIndustry_sector?.label?.replace(/[•\t.+]/g, " ").trim(),
					value: editIndustry_sector?.value,
				});
				setValue("industry_or_sector", editIndustry_sector?.value);
				setLoadingItems(false);
			} catch (err) {
				setLoadingItems(false);
				// console.log('err', err);
			}
		}
	};

	useEffect(() => {
		if (id) {
			populateFieldWithData();
		}
	}, [id]);
	//file upload
	const chooseFileHandle = (e) => {
		//setChooseFile(e.target.files[0]);
		let rVal = validateFile(e.target.files[0]); // return 1 = file type wrong & 2 = file size large
		if (rVal) {
			let errorMsg = 1 === rVal ? t("Invalid file type") : t("File size is not > 1Mb");
			// alertify(errorMsg, true);
			// alertify.error(errorMsg);
			showAlertNotification(errorMsg, "fail");
			e.target.files[0].value = null;
			document.getElementById("myFileInputID").value = null;
		} else {
			setOrganizationlogo(URL.createObjectURL(e.target.files[0]));
			setSelectedFile(e.target.files[0]);
		}
	};
	const deleteFiles = (fileName) => {
		fileName = !isEmpty(selectedFile) ? selectedFile?.name : fileName;
		alertify
			.confirm(
				`${t("Delete file")}`,
				`${t("Do you want to delete this file")} ${fileName}` + `${t("?")}`,
				function () {
					setOrganizationlogo(null);
					setFileDelete(true);
					setSelectedFile(null);
				},
				function () { }
			)
			.set("labels", { ok: t("Ok"), cancel: t("Cancel") })
			.set({ transitionOff: true });
	};
	const createNewOrganizationEvent = async (data) => {
		//ev.preventDefault();
		setIsButtonLoading(true);
		if ("Y" === taxTreatMent) {
			if (data.vatid === "") {
				setIsButtonLoading(false);
				showAlert(t("Please enter the VAT Id"), true);
				return;
			}
		}

		let org_payload = {
			organization_name: {
				en: data.organizationNameEn ? data?.organizationNameEn?.trim() : data?.organizationNameAr?.trim(),
				ar: data.organizationNameAr ? data?.organizationNameAr?.trim() : data?.organizationNameEn?.trim(),
			},
			vat_id: data.vatid,
			company_id: data.companyid,
			fiscal_year_start_month: fiscalYearStartMonth,
			preferred_language: prefferedLanguage,
			phone_number: data.phone_number?.replace(/\D/g, ""),
			website: data.smlink,

		};
		if (fileDelete) {
			org_payload.organization_logo = [];
		}

		try {
			if (selectedFile?.name) {
				let orginalfilename = selectedFile.name;
				let fileTypeInfo = orginalfilename.split(".").pop();
				let rand = Math.floor(Math.random() * 10 + 1);
				let filename = rand + moment().valueOf() + "." + fileTypeInfo;
				let fileName = "upload/" + organization_id + "/" + moment().format("YYYY") + "/" + moment().format("MM") + "/" + filename;
				let attachmentData = {
					upload_path: fileName,
					size: selectedFile.size,
					original_name: selectedFile.name,
				};

				const formData = new FormData();
				formData.append("file", selectedFile);
				formData.append("file_name", fileName);
				formData.append("orginalfilename", orginalfilename);
				formData.append("file_permission", "public-read");
				await uploads(formData);
				org_payload.organization_logo = attachmentData;
			}
			//end logo upload
			if (!id) {
				org_payload = {
					...org_payload,
					currency: data?.currency, //add currency only on create
					country: data?.country,
					//this fields will get inserted into "Head office" branch (address)
					street_1: data.street_1,
					street_2: data.street_2,
					building_no: data.building_no,
					district: data.district,
					additional_no: data.additional_no,
					state: data.state,
					city: data.city,
					zipcode: data.zipcode,
					industry_or_sector: data.industry_or_sector,
					seller_id_type: data.seller_id_type,
					seller_id: data.seller_id,
				};

				let org_create_res = await createNewOrganization(org_payload);
				//  console.log('1st time', org_create_res);

				if (org_create_res) {
					if ("dashboard" === props.redirectUrl) {
						//when add first time org then add to redux
						//update the localstorage with all data
						if (org_create_res[0]?.token) {
							localStorage.setItem("token", org_create_res[0]?.token);
						}

						const user_info = JSON.parse(localStorage.getItem("user_info"));
						user_info["default_organization_id"] = org_create_res[0];
						user_info["role"] = "admin"; //for 1st time organization creation
						localStorage.setItem("user_info", JSON.stringify(user_info));
						//we need to update the store..........
						//update redux store
						dispatch({
							type: UPDATE_ORGANIZATION,
							payload: { userInfo: user_info },
						});
					}
					showAlert(t("Organization register successfull"));
					history.push(`/${language}/${props.redirectUrl}`);
				}
			} else {
				// try {
				org_payload.industry_or_sector = data.industry_or_sector;

				let org_update_res = await updateAOrganization(id, org_payload);
				if (org_update_res) {
					if ("dashboard" === props.redirectUrl) {
						//when add first time org then add to redux
						//update the localstorage with all data
						const user_info = JSON.parse(localStorage.getItem("user_info"));
						user_info["default_organization_id"] = org_update_res[0];
						localStorage.setItem("user_info", JSON.stringify(user_info));
						//we need to update the store..........
						//update redux store
						dispatch({
							type: UPDATE_ORGANIZATION,
							payload: { userInfo: user_info },
						});
					} else if ("organizations" === props.redirectUrl && id === organization_id) {
						updatePrefferedLanguage(org_payload?.preferred_language);
						//update only logged in user organization
						//update the localstorage with all data
						const user_info = JSON.parse(localStorage.getItem("user_info"));
						user_info["default_organization_id"]["organization_name"] = org_payload.organization_name; //only name update
						user_info["default_organization_id"]["preferred_language"] = org_payload?.preferred_language;
						user_info["default_organization_id"]["fiscal_year_start_month"] = org_payload?.fiscal_year_start_month;
						user_info["default_organization_id"]["industry_or_sector"] = org_payload?.industry_or_sector;
						// console.log('user_info', user_info);
						localStorage.setItem("user_info", JSON.stringify(user_info));
						dispatch({
							type: UPDATE_ORGANIZATION,
							payload: { userInfo: user_info },
						});
					}
					showAlert(t("Organization update successfull"));
					history.push(`/${language}/${props.redirectUrl}`);
				}
			}
		} catch (err) {
			console.log(err);
			setIsButtonLoading(false);

			if (Array.isArray(err)) {
				setValidationErrors(err);
			} else {
				setValidationErrors([]);
				showAlert(t(err), true);
			}
		}
	};

	// speed increase
	const setCountryHandler = (val) => {
		setValue("country", val);
		//vat validation if only saudi arabia country selected
		setCountry(val);
		if ("SA" !== val) {
			errors.vatid = false; //set the error false
		}
	};
	const setCurrencyHandler = (val) => {
		setValue("currency", val);
		// setCurrency(val);
	};
	// const setCityHandler = (val) => {
	// 	setCity(val);
	// };

	// const setLanguageHandler = (val) => {
	// 	setPrefferedLanguage(val);
	// };

	//for theme set and update in db
	const setThemeHandler = async (themeId) => {
		try {
			//update theme in db
			let thm_payload = { theme: themeId };
			let thm_update = await patchOrganization(id, thm_payload);
			if (thm_update) {
				updateTheme(themeId); //common function for set theme state
				//set this to localstorage and redux
				if (organization_id === id) {
					//only for own organization
					const user_info = JSON.parse(localStorage.getItem("user_info"));
					user_info.default_organization_id["theme"] = themeId; //push the new value
					localStorage.setItem("user_info", JSON.stringify(user_info));
					//update redux store
					dispatch({
						type: UPDATE_ORGANIZATION,
						payload: { userInfo: user_info },
					});
				}
				showAlert(t("Preference theme has been saved"));
			}
		} catch (err) {
			showAlert(t(err), true);
		}
	};

	//common function for update theme
	const updateTheme = (themeId) => {
		let selectedTheme = THEMELIST?.find((theme) => theme?.themeId === themeId);
		// console.log('selectedTheme', selectedTheme);
		if (selectedTheme) {
			if (organization_id === id) {
				document.body.classList.remove(themeSlug); //remove previous theme slug
				document.body.classList.add(selectedTheme?.themeSlug); //set new theme slug
				setThemeClass(selectedTheme?.themeClass); //set theme class
				setThemeName(selectedTheme?.themeName); //set theme name
				setThemeSlug(selectedTheme?.themeSlug); //update the theme slug for next time
			} else {
				setThemeClass(selectedTheme?.themeClass); //set theme class
				setThemeName(selectedTheme?.themeName); //set theme name
			}
		}
	};

	// common function for update preffered_language
	const updatePrefferedLanguage = (newLang) => {
		let selectedPrefferedLanguage = PREFERRED_LANGUAGE?.find((lang) => lang?.langValue === newLang); //get the selected language
		const prevPrefferdLang = PREFERRED_LANGUAGE?.find((lang) => lang?.langValue === langPrevValue);
		if (selectedPrefferedLanguage) {
			document.body.classList.remove(prevPrefferdLang?.langSlug); //remove previous theme slug
			document.body.classList.add(selectedPrefferedLanguage?.langSlug); //set new theme slug
		}
	};

	//generate options for fiscal year drop down
	function generateFiscalYearOptions(language) {
		let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		const options = [];
		for (let i = 0; i <= 11; ++i) {
			let start_month_index = i;
			let add12 = start_month_index + 11;
			let end_month_index = add12 % 12; //divide add12 by 12
			options.push({
				label: `${months[start_month_index]} - ${months[end_month_index]}`,
				value: i,
			});
		}
		return options;
	}
	const fiscal_years_options = useMemo(() => generateFiscalYearOptions(language), [language]);
	const handleFiscalYearChange = (value) => {
		setFiscalYearStartMonth(value);
	};
	const HandleTaxTreatmentChange = (ev) => {
		let value = ev.target.value;
		setTaxTreatment(value);
		//if use choose not to provide vat after inputuing it
		//set it as empty string,
		if (value === "N") {
			setValue("vatid", "");
		}
	};
	//create industry or sector for drop down
	const industry_sector = useMemo(
		() =>
			INDUSTRY_SECTOR.map((industry) => ({
				value: industry.value,
				label: industry?.label[language],
			})),
		[]
	);
	/******************Auto suggestion for sector unit feild START **********************/
	//Autosuggest component function REQUIRED.
	// const [suggestions, setSuggestions] = useState([]);
	// const getSuggestions = (value) => {
	// 	const inputValue = value.toLowerCase();
	// 	const inputLength = inputValue.length;

	// 	return inputLength === 0 ? industry_sector : industry_sector.filter((each_sector) => each_sector?.label?.toLowerCase()?.slice(0, inputLength) === inputValue);
	// };
	// const getSuggestionValue = (suggestion) => suggestion.value ?? suggestion.label ;
	// const renderSuggestion = (suggestion) => <div>{suggestion?.label}</div>;
	// //for onfocus display the list
	// const shouldRenderSuggestions = () => {
	// 	// setSuggestions(industry_sector)
	// 	// console.log("==here==")
	// 	// return industry_sector;
	// 	return true;
	// };
	// const onSuggestionsFetchRequested = ({ value }) => {
	// 	setSuggestions(getSuggestions(value));
	// };
	// const onSuggestionsClearRequested = () => {
	// 	setSuggestions([]);
	// };
	// let clasNameAutoSuggest = 'form-control ' + (errors.industry_sector ? 'is-invalid' : '');
	// const inputProps = {
	// 	placeholder: t('Select or write'),
	// 	className: clasNameAutoSuggest,
	// 	value: sectorSate ?? '',
	// 	onChange: (event, { newValue }) => {
	// 		setSelectedOption('industry_or_sector', newValue);
	// 	},
	// };

	/******************Auto suggestion for sector industry felid END**********************/
	const setSelectedOption = (type, value) => {
		if ("industry_or_sector" === type) {
			if (value?.value) {
				setSectorSate(value?.value);
				setValue("industry_or_sector", value?.value);
				SetIndustry_sector_value({
					label: value?.label?.replace(/[•\t.+]/g, " ").trim(),
					value: value?.value,
				});
				//need to set the value on onchange. This is not the right way, We will think the validation process later
				errors.sell_account = false; //set the error false
			} else {
				setSectorSate("");
				SetIndustry_sector_value("");
				setValue("industry_or_sector", "");
				errors.sell_account = true; //set the error true
			}
			// if (value) {
			// 	setSectorSate(value);
			// 	setValue('industry_or_sector', value);
			// 	errors.industry_or_sector = false; //set the error false
			// } else {
			// 	setSectorSate('');
			// 	setValue('industry_or_sector', '');
			// 	errors.industry_or_sector = true; //set the error true
			// }
		}
	};

	// phone number validation custom function
	const validateOrgPhoneNumber = (value, dialCode) => {
		// get value with out country code
		const withOutStdCode = value?.replace(`+${dialCode}`, "");
		if (isEmpty(withOutStdCode) && withOutStdCode?.length === 0) {
			setValue("phone_number", "");
			errors.phone_number = true;
		} else {
			setValue("phone_number", withOutStdCode);
			errors.phone_number = false;
		}
	};

	return (
		<>
			<Col className={`rgtpnl${"dashboard" === props.redirectUrl ? " notRegd" : ""}`}>
				{loadingItems && <Loading editPage="true" />}
				<div className="conts">
					<div className="frmbox">
						<section className="contsbox p-4 pt-0 pb-0">
							{validationErrors && validationErrors?.length > 0 && (
								<ValidationErrorMessage
									validationErrors={validationErrors}
									parentCallback={() => {
										setValidationErrors([]);
									}}
								/>
							)}
						</section>
						<h4 className="mb-4">{t("Please add organization details")}</h4>
						<Form onSubmit={handleSubmit(createNewOrganizationEvent)}>
							<div className="orgformbox w-100">
								<section className="orgtxtform">
									<div className="fr_b">
										<div className="orgformtop">
											<section className="frms">
												<Row>
													{("organizations" === props.redirectUrl || "ar" === language) && (
														<>
															<Col xs="12" md={"organizations" === props.redirectUrl ? "6" : "12"} className="onlyARshow">
																<FormGroup>
																	<Label for="orgnamea">
																		{t("Organization name in Arabic")}
																		<i>*</i>
																	</Label>
																	<input
																		onChange={copyArabicToEnglishHandler}
																		type="text"
																		name="organizationNameAr"
																		defaultValue={arabicName}
																		id="orgnamea"
																		dir="rtl"
																		// {...register('organizationNameAr')}
																		className={`form-control ${errors.organizationNameAr ? "is-invalid" : ""}`}
																	/>
																	<div className="invalid-feedback">{errors.organizationNameAr?.message}</div>
																</FormGroup>
															</Col>
														</>
													)}
													{("organizations" === props.redirectUrl || "en" === language) && (
														<>
															<Col xs="12" md={"organizations" === props.redirectUrl ? "6" : "12"} className="onlyENshow">
																<FormGroup>
																	<Label for="orgnamee">
																		{t("Organization name in English")}
																		<i>*</i>
																	</Label>
																	<input
																		onChange={copyEnglishToArabicHandler}
																		type="text"
																		defaultValue={englishName}
																		name="organizationNameEn"
																		id="orgnamee"
																		dir="ltr"
																		// {...register('organizationNameEn')}
																		className={`form-control ${errors.organizationNameEn ? "is-invalid" : ""}`}
																	/>
																	<div className="invalid-feedback">{errors.organizationNameEn?.message}</div>
																	<div className="invalid-feedback">{errors.organizationNameAr?.message}</div>
																</FormGroup>
															</Col>
														</>
													)}

													<Col xs="12" md="6">
														<FormGroup>
															<Label for="currency">
																{t("Tax treatment")}
																<i>*</i>
															</Label>
															<Input
																type="select"
																name="tax_treatment"
																value={taxTreatMent}
																onChange={(ev) => {
																	HandleTaxTreatmentChange(ev);
																}}
															>
																<option value="N">{t("Non VAT registered")}</option>
																<option value="Y">{t("VAT registered")}</option>
															</Input>
															<div className="invalid-feedback"></div>
														</FormGroup>
													</Col>
													{taxTreatMent === "Y" && (
														<Col xs="12" md="6">
															<FormGroup>
																<Label for="vatid">{t("Vat ID")}</Label>
																<input
																	type="text"
																	defaultValue={organizationData?.vat_id}
																	name="vatid"
																	id="vatid"
																	// className="form-control"
																	{...register("vatid")}
																	className={`form-control ${errors.vatid ? "is-invalid" : ""}`}
																/>
																<section className="d-flex" style={{ boxShadow: "none", borderRadius: "0" }}>
																	<div className="invalid-feedback flex-grow-1">{errors.vatid?.message}</div>
																	{organizationData?.country === "SA" && (
																		<div className="text-nowrap" style={{ paddingTop: "2px" }}>
																			<a
																				href="https://zatca.gov.sa/en/eServices/Pages/TaxpayerLookup.aspx"
																				className="hvrline"
																				target={"_blank"}
																				rel="noreferrer"
																				style={{ fontSize: "0.759rem" }}
																			>
																				{t("Validate TRN")}
																			</a>
																		</div>
																	)}
																</section>
															</FormGroup>
														</Col>
													)}
												</Row>
												<Row>
													<Col xs="12" md="6">
														<FormGroup>
															<Label for="currency">
																{t("Currency")}
																<i>*</i>
															</Label>
															{/* <Input
														type="select"
														value={currency}
														name="currency"
														className="form-select"
														disabled={id ? true : false}
														onChange={(e) => setCurrencyHandler(e.target.value)}
													>
														{CURRENCYLIST &&
															CURRENCYLIST?.map((currency, index) => (
																<option key={index} value={currency.code}>
																	[{currency.code}] {'ar' === language ? currency.ar : currency.en}
																</option>
															))}
													</Input> */}

															<Controller
																name={"currency"}
																control={control}
																render={({ field: { ref, value, onChange } }) => (
																	<Select
																		ref={ref}
																		isDisabled={id ? true : false}
																		value={currencyCodeOption.find((cc) => cc.value === value)}
																		placeholder={t("Select currency")}
																		options={currencyCodeOption}
																		onChange={(v) => setCurrencyHandler(v.value)}
																	// className={` ${errors.currency_code ? 'is-invalid' : ''}`}
																	/>
																)}
															/>
															<div className="invalid-feedback"></div>
														</FormGroup>
													</Col>
													<Col xs="12" md="6">
														<FormGroup>
															<Label for="industry">
																{t("Industry / Sector")}
																<i>*</i>
															</Label>

															<Select
																tabIndex={3}
																isClearable={true}
																placeholder={t("Select industry or sector")}
																name="industry_or_sector"
																className={` ${errors.industry_or_sector ? "is-invalid" : ""}`}
																onChange={(obj) => {
																	setSelectedOption("industry_or_sector", obj);
																}}
																value={industry_sector_value || ""}
																options={industry_sector}
															/>
															<div className="invalid-feedback">{errors.industry_or_sector?.message}</div>
														</FormGroup>
													</Col>
													{/* {!id && (
												<Col xs="12" md="6">
													<FormGroup className="frmfld pb-1">
														<Label>
															{t('Seller / Company ID')}															
														</Label>
														<section style={{ boxShadow: 'none', borderRadius: '0' }}>
															<Row className="gx-1">
																<Col style={{ maxWidth: '100px' }}>
																	<select
																		type="select"
																		name="seller_id_type"
																		{...register('seller_id_type')}
																		className={`form-select ${errors.seller_id_type ? 'is-invalid' : ''}`}
																	>
																		<option value="">{t('Seller ID')}</option>
																		{BUYER_ID_LABEL_ARRAY &&
																			BUYER_ID_LABEL_ARRAY.map((title, index) => (
																				<option key={index} value={title}>
																					{title}
																				</option>
																			))}
																	</select>
																	<div className="invalid-feedback">{errors.seller_id_type?.message}</div>
																</Col>
																<Col>
																	<input
																		type="text"
																		placeholder={t('Seller ID')}
																		title={t('Seller ID')}
																		name="seller_id"
																		{...register('seller_id')}
																		className={`form-control ${errors.seller_id ? 'is-invalid' : ''}`}
																	/>
																	<div className="invalid-feedback">{errors.seller_id?.message}</div>
																</Col>
															</Row>
														</section>
													</FormGroup>
												</Col>
											)} */}
												</Row>

												{/* <Row>
											<Col xs="12" md="6">
												<FormGroup>
													<Label for="industry">{t('Industry / Sector')}</Label>
													<Autosuggest tabIndex={3}
														suggestions={suggestions}
														onSuggestionsFetchRequested={onSuggestionsFetchRequested}
														onSuggestionsClearRequested={onSuggestionsClearRequested}
														shouldRenderSuggestions={shouldRenderSuggestions}
														getSuggestionValue={getSuggestionValue}
														renderSuggestion={renderSuggestion}
														inputProps={inputProps}
													/>
													<div className="invalid-feedback">{errors.industry_or_sector?.message}</div>
												</FormGroup>
											</Col>
										</Row> */}
											</section>
											<section className="phts">
												{"organizations" === props?.redirectUrl && (
													<section className="orgphtform">
														<div style={{ paddingBottom: "calc(0.275rem + 1px)" }}>{t("Organization logo")}</div>
														<div
															className={
																(!fileDelete && organizationData?.organization_logo?.length > 0) || selectedFile
																	? "orgpht customimgupload hasimg"
																	: "orgpht customimgupload"
															}
														>
															<label
																style={{
																	backgroundImage: `url(${organizationlogo})`,
																}}
															>
																<input type="file" id="myFileInputID" name="organization_logo" onChange={(e) => chooseFileHandle(e)} />
																<span>
																	<Icon.Upload /> <br />
																	{t("Upload image")}
																</span>
															</label>
															<a
																onClick={() => {
																	deleteFiles(organizationData?.organization_logo[0]?.original_name);
																}}
															>
																<Icon.Trash style={{ marginTop: "-2px" }} /> {t("Delete")}
															</a>
														</div>
														<div className="smlitl mt-1">{t("*Maximum width 800px and maximum height 240px - 300 dpi")}</div>
													</section>
												)}
											</section>
										</div>
										{/*---------orgformtop end---------*/}
										<section style={{ maxWidth: "768px" }}>
											<h5 className="mt-3 mb-3 txt-thm2">{t("Address")}</h5>
											<Row>
												<Col xs="12" md="6">
													<FormGroup>
														<Label for="vatid">{t("Country")}</Label>

														<Controller
															name={"country"}
															control={control}
															render={({ field: { ref, value, onChange } }) => (
																<Select
																	ref={ref}
																	isDisabled={id ? true : false}
																	value={countryCodeOption.find((cc) => cc.value === value)}
																	placeholder={t("Select country")}
																	options={countryCodeOption}
																	onChange={(v) => setCountryHandler(v.value)}
																// className={` ${errors.currency_code ? 'is-invalid' : ''}`}
																/>
															)}
														/>
														<div className="invalid-feedback"></div>
													</FormGroup>
												</Col>
												{!id && (
													<>
														<Col xs="12" md="6">
															<FormGroup>
																<Label for="street_1">
																	{t("Street 1")}
																	{"SA" === country && <i>*</i>}
																</Label>

																<input
																	type="text"
																	name="street_1"
																	{...register("street_1")}
																	id="street_1"
																	className={`form-control ${errors.street_1 ? "is-invalid" : ""}`}
																/>
																<div className="invalid-feedback">{errors.street_1?.message}</div>
															</FormGroup>
														</Col>
														<Col xs="12" md="6">
															<FormGroup>
																<Label for="street_2">{t("Street 2")}</Label>
																<input
																	type="text"
																	name="street_2"
																	{...register("street_2")}
																	id="street_2"
																	className={`form-control ${errors.street_2 ? "is-invalid" : ""}`}
																/>
																<div className="invalid-feedback">{errors.street_2?.message}</div>
															</FormGroup>
														</Col>
														<Col xs="12" md="6">
															<FormGroup>
																<Label for="building_no">
																	{t("Building number")}
																	{"SA" === country && <i>*</i>}
																</Label>
																<input
																	type="text"
																	name="building_no"
																	{...register("building_no")}
																	id="building_no"
																	className={`form-control ${errors.building_no ? "is-invalid" : ""}`}
																/>
																<div className="invalid-feedback">{errors.building_no?.message}</div>
															</FormGroup>
														</Col>
														<Col xs="12" md="6">
															<FormGroup>
																<Label for="additional_no">{t("Address additional number")}</Label>
																<input
																	type="text"
																	name="additional_no"
																	{...register("additional_no")}
																	id="additional_no"
																	className={`form-control ${errors.additional_no ? "is-invalid" : ""}`}
																/>
																<div className="invalid-feedback">{errors.additional_no?.message}</div>
															</FormGroup>
														</Col>
														<Col xs="12" md="6">
															<FormGroup>
																<Label for="state">{t("State")}</Label>
																<input
																	type="text"
																	name="state"
																	{...register("state")}
																	id="state"
																	className={`form-control ${errors.state ? "is-invalid" : ""}`}
																/>
																<div className="invalid-feedback">{errors.state?.message}</div>
															</FormGroup>
														</Col>
														<Col xs="12" md="6">
															<FormGroup>
																<Label for="city">
																	{t("City")}
																	{"SA" === country && <i>*</i>}
																</Label>
																<input
																	type="text"
																	name="city"
																	{...register("city")}
																	id="city"
																	className={`form-control ${errors.city ? "is-invalid" : ""}`}
																/>
																<div className="invalid-feedback">{errors.city?.message}</div>
															</FormGroup>
														</Col>
														<Col xs="12" md="6">
															<FormGroup>
																<Label for="district">
																	{t("District")}
																	{"SA" === country && <i>*</i>}
																</Label>
																<input
																	type="text"
																	name="district"
																	{...register("district")}
																	id="bulding_no"
																	className={`form-control ${errors.district ? "is-invalid" : ""}`}
																/>
																<div className="invalid-feedback">{errors.district?.message}</div>
															</FormGroup>
														</Col>
														<Col xs="12" md="6">
															<FormGroup>
																<Label for="state">
																	{t("Postal code")}
																	{"SA" === country && <i>*</i>}
																</Label>
																<input
																	type="text"
																	name="zipcode"
																	{...register("zipcode")}
																	id="state"
																	className={`form-control ${errors.zipcode ? "is-invalid" : ""}`}
																/>
																<div className="invalid-feedback">{errors.zipcode?.message}</div>
															</FormGroup>
														</Col>
														{/* <Col xs="12" md="6">
														<FormGroup>
															<Label for="orgadrs">{t('Organization address')}</Label>
															<input
																type="textarea"
																defaultValue={organizationData?.organization_address}
																name="organization_address"
																id="organization_address"
																{...register('organization_address')}
																className={`form-control ${errors.organization_address ? 'is-invalid' : ''}`}
															/>
															<div className="invalid-feedback">{errors.organization_address?.message}</div>
														</FormGroup>
													</Col>												 */}
														{/* <Col xs="12" md="6">
														<FormGroup>
															<Label for="industry">{t('Industry / Sector')}</Label>
															<input
																// onChange={}
																type="text"
																name="industry_or_sector"
																defaultValue={organizationData?.industry_or_sector}
																{...register('industry_or_sector')}
																className={`form-control ${errors.industry_or_sector ? 'is-invalid' : ''}`}
															/>
															<div className="invalid-feedback">{errors.industry_or_sector?.message}</div>
														</FormGroup>
													</Col> */}
													</>
												)}
											</Row>
											{id && (
												<div className="p-3 rounded" style={{ border: "1px dotted #b9d0e3", backgroundColor: "#f6fbff" }}>
													<p className="fw-5">
														{/* {t('Since Branches has been enabled for your organization, you can add or edit your address from the Branches section.')} */}
														{t("You can add or edit your address from the Branches section.")}
													</p>
													<Link className="thm1 btn btn-primary btn-sm" to={`/${language}/settings/branches`}>
														{t("Go to Branches")}
													</Link>
												</div>
											)}
											<h5 className="mt-3 mb-3 txt-thm2">{t("Others")}</h5>
											<Row>
												{"organizations" === props?.redirectUrl && id && (
													<Col xs="12" md="6">
														<FormGroup className="slcttheme">
															<Label for="thmclr">{t("Theme")}</Label>
															<Dropdown>
																<Dropdown.Toggle variant="outline-secondary form-control w-100 d-flex justify-content-between align-items-center noprint">
																	<div>
																		<span className={`thmclr ${theme}`}>
																			<code></code>
																		</span>
																		<span>{t(themeName)}</span>
																	</div>
																</Dropdown.Toggle>
																<Dropdown.Menu className="w-100">
																	{THEMELIST &&
																		THEMELIST?.map((eachTheme, index) => (
																			<Dropdown.Item key={eachTheme?.themeId} onClick={() => setThemeHandler(eachTheme?.themeId)}>
																				<div>
																					<span className={`thmclr ${eachTheme?.themeClass}`}>
																						<code></code>
																					</span>
																					<span>{t(eachTheme?.themeName)}</span>
																				</div>
																			</Dropdown.Item>
																		))}
																</Dropdown.Menu>
															</Dropdown>
															<div className="invalid-feedback"></div>
														</FormGroup>
													</Col>
												)}

												{/* TODO:: LATER UNCOMMENT NOT DELETE 
											<Col xs="12" md="6">
												<FormGroup>
													<Label for="prflang">{t('Preferred language')}</Label>
													<Input
														type="select"
														value={prefferedLanguage}
														name="preferred_language"
														className="form-select"
														onChange={(e) => setLanguageHandler(e.target.value)}
													>
														{PREFERRED_LANGUAGE &&
															PREFERRED_LANGUAGE?.map((eachLang) => (
																<option key={eachLang?.langValue} value={eachLang?.langValue}>
																	{t(eachLang?.label)}
																</option>
															))}
													</Input>
													<div className="invalid-feedback"></div>
												</FormGroup>
											</Col> */}
												<Col xs="12" md="6">
													<FormGroup>
														<Label for="prflang">{t("Fiscal year")}</Label>
														<Input
															type="select"
															value={fiscalYearStartMonth}
															name="fiscal_year_start_month"
															className="form-select"
															onChange={(e) => handleFiscalYearChange(e.target.value)}
														>
															{fiscal_years_options &&
																fiscal_years_options?.map((opt) => (
																	<option key={opt?.value} value={opt?.value}>
																		{t(opt?.label)}
																	</option>
																))}
														</Input>
														<div className="invalid-feedback"></div>
													</FormGroup>
												</Col>
												<Col xs="12" md="6">
													<FormGroup>
														<Label for="smlink">{t("Website")}</Label>
														<input
															type="text"
															defaultValue={organizationData?.website}
															name="smlink"
															id="smlink"
															className="form-control"
															placeholder={t("www.example.com")}
															{...register("smlink")}
														/>
														<div className="invalid-feedback"></div>
													</FormGroup>
												</Col>

												<Col xs="12" md="6">
													<FormGroup>
														<Label for="phone_number">
															{t("Phone number")}
															<i>*</i>
														</Label>
														{/* <input
															type="text"
															defaultValue={organizationData?.phone_number}
															name="phone_number"
															id="phone_number"
															className="form-control"
															placeholder={t("Phone number")}
															{...register("phone_number")}
														/> */}
														<CommonPhoneInput
															value={organizationData?.phone_number}
															onChange={(value, country) => {
																const dialCode = country?.country?.dialCode; // get the dial code
																validateOrgPhoneNumber(value, dialCode);
															}}
															register={register}
															fieldName="phone_number"
														/>
														<div className="invalid-feedback">{errors.phone_number?.message}</div>
													</FormGroup>
												</Col>
											</Row>
										</section>
										{/*
									<Row>
										<Col xs="12" sm="6">
											<FormGroup>
												<Label for="orgnamee">
													{t('Organization name in English')}
												</Label>
												<input
													onChange={copyEnglishToArabicHandler}
													type="text"
													defaultValue={englishName}
													name="organizationNameEn"
													id="orgnamee"
													{...register('organizationNameEn')}
													className={`form-control ${
														errors.organizationNameEn ? 'is-invalid' : ''
													}`}
												/>
												<div className="invalid-feedback">
													{errors.organizationNameEn?.message}
												</div>
											</FormGroup>
											<FormGroup>
												<Label for="orgnamea">
													{t('Organization name in Arabic')}
												</Label>
												<input
													onChange={copyArabicToEnglishHandler}
													type="text"
													name="organizationNameAr"
													defaultValue={arabicName}
													id="orgnamea"
													{...register('organizationNameAr')}
													className={`form-control ${
														errors.organizationNameAr ? 'is-invalid' : ''
													}`}
												/>
												<div className="invalid-feedback">
													{errors.organizationNameAr?.message}
												</div>
											</FormGroup>
										</Col>
										<Col xs="12" sm="6">
											<FormGroup>
												<Label for="vatid">{t('Vat ID')} </Label>
												<input
													type="text"
													defaultValue={organizationData?.vat_id}
													name="vatid"
													id="vatid"
													className="form-control"
													{...register('vatid')}
												/>
												<div className="invalid-feedback"></div>
											</FormGroup>
											<FormGroup>
												<Label for="smlink">{t('Website')} </Label>
												<input
													type="text"
													defaultValue={organizationData?.website}
													name="smlink"
													id="smlink"
													className="form-control"
													placeholder={t('Like facebook, twitter...')}
													{...register('smlink')}
												/>
												<div className="invalid-feedback"></div>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col xs="12" sm="6">
											<FormGroup>
												<Label for="vatid">{t('Country')} </Label>
												<Input
													type="select"
													value={country}
													name="country"
													className="form-select"
													onChange={(e) => {
														setCountryHandler(e.target.value);
													}}
												>
													{COUNTRYLIST &&
														COUNTRYLIST.map((country, index) => (
															<option key={index} value={country.iso2}>
																{'ar' == language ? country.ar : country.en}
															</option>
														))}
												</Input>
												<div className="invalid-feedback"></div>
											</FormGroup>
										</Col>
										<Col xs="12" sm="6">
											<FormGroup>
												<Label for="currency">{t('Currency')} </Label>
												<Input
													type="select"
													value={currency}
													name="currency"
													className="form-select"
													disabled={id ? true : false}
													onChange={(e) => setCurrencyHandler(e.target.value)}
												>
													{CURRENCYLIST &&
														CURRENCYLIST?.map((currency, index) => (
															<option key={index} value={currency.value}>
																{'ar' == language ? currency.ar : currency.en}
															</option>
														))}
												</Input>
												<div className="invalid-feedback"></div>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col xs="12" sm="6">
											<FormGroup>
												<Label for="city">{t('City')} </Label>
												<Input
													type="text"
													value={city || ''}
													name="city"
													onChange={(e) => setCityHandler(e.target.value)}
												></Input>
												<div className="invalid-feedback"></div>
											</FormGroup>
										</Col>
										<Col xs="12" sm="6">
											<FormGroup>
												<Label for="orgadrs">
													{t('Organization address')}{' '}
												</Label>
												<input
													type="textarea"
													defaultValue={organizationData?.organization_address}
													name="organization_address"
													id="organization_address"
													{...register('organization_address')}
													className={`form-control ${
														errors.organization_address ? 'is-invalid' : ''
													}`}
												/>
												<div className="invalid-feedback">
													{' '}
													{errors.organization_address?.message}
												</div>
											</FormGroup>
										</Col>
									</Row>
									{'organizations' === props?.redirectUrl && id && (
										<Row>
											<Col xs="12" sm="6">
												<FormGroup className="slcttheme">
													<Label for="thmclr">{t('Theme')}</Label>
													<Dropdown>
														<Dropdown.Toggle variant="outline-secondary form-control w-100 d-flex justify-content-between align-items-center noprint">
															<div>
																<span className={`thmclr ${theme}`}>
																	<code></code>
																</span>
																<span>{t(themeName)}</span>
															</div>
														</Dropdown.Toggle>
														<Dropdown.Menu className="w-100">
															{THEMELIST &&
																THEMELIST?.map((eachTheme, index) => (
																	<Dropdown.Item
																		key={eachTheme?.themeId}
																		onClick={() =>
																			setThemeHandler(eachTheme?.themeId)
																		}
																	>
																		<div>
																			<span
																				className={`thmclr ${eachTheme?.themeClass}`}
																			>
																				<code></code>
																			</span>
																			<span>{t(eachTheme?.themeName)}</span>
																		</div>
																	</Dropdown.Item>
																))}
														</Dropdown.Menu>
													</Dropdown>
													<div className="invalid-feedback"></div>
												</FormGroup>
											</Col>
										</Row>
									)}
									
*/}
									</div>
									<FormGroup>
										<Button color="primary" block className={`thm2 ${isButtonLoading ? "loading" : ""}`} disabled={isButtonLoading}>
											{t("Submit")}
										</Button>
									</FormGroup>
								</section>
							</div>
							{/*-----------Org form box----------*/}
						</Form>
					</div>
				</div>
			</Col>
		</>
	);
};
export default AddOrganizationForm;
