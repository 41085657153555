import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import './../../translations/i18n';
import { useTranslation } from 'react-i18next';
const ValidationErrorMessage = ({validationErrors, parentCallback}) => {
	const { t } = useTranslation();
	const closeMessage = () => {
		parentCallback();
	};	
	return (
			<Alert variant="danger lite lh-15">
				<Button className="close" variant="link close p-2 nofcs lh-1 f12x" onClick={closeMessage}>
					<Icon.XLg />
				</Button>
				<ul style={{ marginBottom: '-0.5rem' }}>
					{validationErrors?.length > 0 && validationErrors.filter((item, i, validationErrors) => validationErrors.indexOf(item) === i).map((message, ind) => <li className="mb-2" key={ind}>{t(message?.message?? message)}</li>)}
				</ul>
			</Alert>
	);
};

export default ValidationErrorMessage;
