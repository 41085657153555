import React, { useRef, useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import moment from 'moment';
import { Button, Row, Col } from 'reactstrap';
import { Form, Modal } from 'react-bootstrap';
import '../../translations/i18n';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ContactDropdownForSearch, UserDropdownForSearch } from '../formField/ContactDropdownForSearch';
import ItemDropdownForSearch from '../formField/ItemDropdownForSearch';
import AccountsDropdown from '../formField/AccountsDropdown';
import Select from 'react-select';
import { getAllAdjustmentReasons } from "../../services/organizatiom.service";
import { TAX_CATEGORY, INVOICE_REPEAT_CUSTOM_OPTION, TRANSACTION_STATUS_OPTION, INVENTORY_ADJUSTMENT_TYPE, TRANSACTION_STATUS_OPTION_FOR_INV_ADJUSTMENT } from "../../util/helper";


import SalesPersonsDropdown from '../formField/SalesPersonsDropdown';
const PopupSearchForm = ({ handleSrchClose, search_model, fieldArray }) => {
	const { hash } = useLocation();
	const documentType = false !== hash ? hash.split("#")[1] : ""; //get string after # symbol
	// issue date range
	const issue_date_to_ref = new useRef("");
	const issue_date_from_ref = new useRef("");
	// created date range
	const created_date_to_ref = new useRef("");
	const created_date_from_ref = new useRef("");
	// inventory adjustment date range
	const adjustment_date_from_ref = new useRef("");
	const adjustment_date_to_ref = new useRef("");
	// transfer order date range
	const transfer_date_from_ref = new useRef("");
	const transfer_date_to_ref = new useRef("");
	const history = useHistory();
	const { t } = useTranslation();
	const { search } = useLocation();
	const language = useSelector((state) => state.auth.language);
	const { default_organization_id } = useSelector((state) => state.auth.userInfo);
	const { stores: STORE_LISTS, branches } = useSelector((state) => state.auth.userInfo.default_organization_id);
	const [adjustmentReason, setAdjustmentReason] = useState([]);

	//redux account setting
	const {
		expense_accounts: EXPENSE_ACCOUNTS_CONFIG,
		expense_paid_through_accounts: EXPENSE_PAID_THROUGH_ACCOUNT_CONFIG,
		collection_accounts: COLLECTION_ACCOUNTS_CONFIG,
		sales_accounts: SALES_ACCOUNTS_CONFIG,
		purchase_accounts: PURCHASE_ACCOUNTS_CONFIG
	} = useSelector((state) => state.auth.userInfo.account_settings ?? {});
	let taxes = default_organization_id?.taxes; //tax from redux
	let payment_modes = default_organization_id?.payment_modes;
	let queryParams = queryString.parse(search); //search query string
	const unitList = default_organization_id?.units; //set unit data fron redux state


	//update this object whenever user changes search criteria
	let queryObject = {
		...queryParams, //other query params
		search: true, //seach mode is on
		model: search_model, //seach model name
	};
	//create query string from queryObject and push
	const createQueryString = () => {
		//we just remove some key and empty because search value conflict with common search if boath search value present in url
		// queryObject.queryParams = {};
		delete queryObject["search_field"];
		delete queryObject["search_value"];
		queryObject.page = 1; //reset page to 1
		if ("chartofaccounts" === search_model) {
			queryObject.mode = "all";
			search_model = `${"accounting/chartofaccounts"}`;
		} else if ("journal" === search_model) {
			search_model = `${"accounting/journals"}`;
		} else if ("recurring-journals" === search_model) {
			search_model = `${"accounting/recurring-journals"}`;
		} else if ("organizations" === search_model) {
			search_model = `${"organizations-list"}`;
		} else if ("transfer-orders" === search_model) {
			search_model = `${"inventory/transfer_orders"}`;

		} else if ("adjustments" === search_model) {
			search_model = `${"inventory/adjustments"}`;

		}

		if (documentType) {
			let doc_type_selector = queryObject?.document_type ? queryObject.document_type : "all";
			history.push(`/${language}/${search_model}` + "?" + queryString.stringify(queryObject) + "#" + doc_type_selector);
		} else {
			history.push(`/${language}/${search_model}` + "?" + queryString.stringify(queryObject));
		}
	};
	//when user input values
	//searchId means customer_id,account_id,item_id etc
	const onEntryChange = (field_name, field_value, field_id, seachId = "") => {
		//selected id only for label value selection search
		// let selectedId = 'contact_name' === field_name ? 'customer_id' : 'deposit_account_name' === field_name ? 'deposit_to' : 'account_id';
		if (field_value?.toString().trim() !== "" && "undefined" !== typeof field_value) {
			if ("issue_date_from" === field_name && !issue_date_to_ref.current.value) {
				issue_date_to_ref.current.value = field_value;
				onEntryChange("issue_date_to", field_value);
			}
			if ("issue_date_to" === field_name && !issue_date_from_ref.current.value) {
				issue_date_from_ref.current.value = field_value;
				onEntryChange("issue_date_from", field_value);
			}
			if ("created_date_from" === field_name && !created_date_to_ref.current.value) {
				created_date_to_ref.current.value = field_value;
				onEntryChange("created_date_to", field_value);
			}
			if ("created_date_to" === field_name && !created_date_from_ref.current.value) {
				created_date_from_ref.current.value = field_value;
				onEntryChange("created_date_from", field_value);
			}
			// adjustment date
			if ("adjustment_date_from" === field_name && !adjustment_date_to_ref.current.value) {
				adjustment_date_to_ref.current.value = field_value;
				onEntryChange("adjustment_date_to", field_value);
			}
			if ("adjustment_date_to" === field_name && !adjustment_date_from_ref.current.value) {
				adjustment_date_from_ref.current.value = field_value;
				onEntryChange("adjustment_date_from", field_value);
			}
			// transfer order date
			if ("transfer_date_from" === field_name && !transfer_date_to_ref.current.value) {
				transfer_date_to_ref.current.value = field_value;
				onEntryChange("transfer_date_to", field_value);
			}
			if ("transfer_date_to" === field_name && !transfer_date_from_ref.current.value) {
				transfer_date_from_ref.current.value = field_value;
				onEntryChange("transfer_date_from", field_value);
			}


			if (seachId) {
				//for id seach
				queryObject[seachId] = field_id; //only for label value selection search like customer, account, tax
			}
			queryObject[field_name] = field_value; //set value to queryObject
		}
		//else remove the key from queryObject
		else {
			if (seachId) {
				delete queryObject[seachId];
			}
			delete queryObject[field_name];
		}

		//clear tax search
		// TODO: Need to check, block by Susovon
		// if (!field_value && !field_id) {
		// 	createQueryString();
		// }
	};

	const chart_of_account_all = useSelector((state) => state.auth.userInfo.chart_of_account);
	// fetch reason list
	useEffect(() => {
		if (search_model === "adjustments") {

			const loadAdjustmentReasons = async () => {
				try {
					const response = await getAllAdjustmentReasons();
					setAdjustmentReason(response?.data);
				} catch (error) { }
			};
			loadAdjustmentReasons()
		}
	}, [search_model])


	const branch_RSelect = useMemo(
		() => branches?.map((each_branch) => ({
			value: each_branch?._id,
			label: each_branch?.branch_name?.[language]
		})),
		[branches, language]
	)


	const adjustment_reasons_RSelect = useMemo(
		() =>
			[...adjustmentReason].map((item) => ({
				value: item._id,
				label: item.reason?.[language],
			})),
		[adjustmentReason, language],
	);
	const account_groups_options = useMemo(
		() =>
			chart_of_account_all
				?.filter((acc) => acc.depth === 0 && acc?.account_category === "AT")
				?.map((acc) => ({ value: acc._id, label: acc.title?.[language] })),
		[language, chart_of_account_all]
	);

	//make tax list (merge TAX_CATEGORY && taxes)
	const taxOptionsObject = useMemo(() => {
		let system_tax = TAX_CATEGORY?.default_categories?.map((tax) => ({
			label: tax[language],
			value: { value: tax.value, tax_category: tax.tax_category },
		}));
		//don't show 0% as standard rate
		let user_tax = taxes
			?.map((tax) => (tax?.tax_category ? tax : { ...tax, tax_category: "S" }))
			?.map((tax) => ({ label: tax[language] + " - " + tax.value + "%", value: { value: tax.value, tax_category: tax.tax_category } })); //have structure  {ar : "tax name", en: "tax name", value : a number , tax_category : string | undefined}
		let tax_list = [...system_tax, ...user_tax];
		return tax_list;
	}, [taxes, language]);


	//set selected tax option
	const setTaxOption = () => {
		const selected_option = taxOptionsObject?.find(each_tax => (each_tax?.value?.value === Number(queryParams?.item_tax) && each_tax?.value?.tax_category === queryParams?.item_tax_category))
		return selected_option;
		// return queryParams?.item_tax ? { label: tax[language] + " - " + queryParams?.item_tax + "%", value: { value: queryParams?.item_tax, tax_category: queryParams?.item_tax_category } } : null
	}


	// set selected frequency
	const setFrequencyOption = () => {
		const selected_option = INVOICE_REPEAT_CUSTOM_OPTION?.find(each_option => (each_option?.value === queryParams?.frequency))
		return selected_option;
	}

	// store management (store ware house && destination ware house) drop down list
	const stores_options = useMemo(() => {
		if (STORE_LISTS?.length > 0) {
			let storeOptions = STORE_LISTS?.map((store) => {
				return {
					value: store._id,
					label: store.store_name?.[language],
					store_address: store.store_address, // for displaying the address when selecting the store
				};
			});
			// set source & destination store address
			return {
				source_warehouse: storeOptions,
				destination_warehouse: storeOptions,
			};
		}
	}, [STORE_LISTS, language,]);


	// set selected option for transaction status for transfer orders
	const setTransactionStatusOption = () => {
		// FIND STATUS ARRAY BASED UPON THE search_model
		let selected_option;
		if ("adjustments" === search_model) {
			selected_option = TRANSACTION_STATUS_OPTION_FOR_INV_ADJUSTMENT?.find(each_option => (each_option?.value === queryParams?.transaction_status))
		}
		if ("transfer-orders" === search_model) {
			selected_option = TRANSACTION_STATUS_OPTION?.find(each_option => (each_option?.value === queryParams?.transaction_status))
		}
		return selected_option;
	}
	// set selected option for source ware house
	const setSourceStoreOption = () => {
		const selected_option = stores_options?.source_warehouse?.find(each_option => (each_option?.value === queryParams?.from_store_id))
		return selected_option;
	}
	// set selected option for destination ware house
	const setDestinationStoreOption = () => {
		const selected_option = stores_options?.destination_warehouse?.find(each_option => (each_option?.value === queryParams?.to_store_id))
		return selected_option;
	}
	// set inventory adjustment option
	const setInventoryAdjustmentOption = () => {
		const selected_option = INVENTORY_ADJUSTMENT_TYPE?.find(each_option => (each_option?.value === queryParams?.adjustment_type))
		return selected_option;
	}

	// set inventory adjustment reason
	const setInventoryAdjustmentReason = useMemo(() => {
		if (queryParams?.reason && queryParams?.reason_id) {

			return {
				label: queryParams?.reason,
				value: queryParams?.reason_id
			}
		} else {
			return [];
		}
	}, [queryParams?.reason_id, queryParams?.reason])

	const setDefaultBranchId = () => {
		const selected_option = branch_RSelect?.find(each_option => (each_option?.value === queryParams?.branch_id))
		return selected_option;
	}
	return (
		<>
			<Modal.Body className="pb-0">
				{"true" === process.env.REACT_APP_BASE_SHOW_PATH_HINTS && (
					<p style={{ color: "red" }}>src/common/searchForms/PopupSearchForm.js</p>
				)}
				<div className="frmbox">
					<Row>
						{["signup-request"].includes(search_model) && (
							<>

								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t("Organization name")}
										</Form.Label>
										<Col>
											<Form.Control
												type="text"
												onChange={(ev) => {
													onEntryChange("organization_name", ev.target.value);
												}}
												name={"organization_name"}
												defaultValue={queryParams?.organization_name}
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t("E-mail")}
										</Form.Label>
										<Col>
											<Form.Control
												type="text"
												onChange={(ev) => {
													onEntryChange("email_id", ev.target.value);
												}}
												name={"email_id"}
												defaultValue={queryParams?.email_id}
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t("Phone number")}
										</Form.Label>
										<Col>
											<Form.Control
												type="text"
												onChange={(ev) => {
													onEntryChange("phone_no", ev.target.value);
												}}
												name={"phone_no"}
												defaultValue={queryParams?.phone_no}
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>

							</>
						)} 	
						{/* For Recurring invoices, bills ,expenses (profile section)*/}
						{['recurring-invoices', 'recurring-bills', 'recurring-expenses', 'recurring-journals'].includes(search_model) && (
							<>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t(fieldArray?.profile_name?.label)}#
										</Form.Label>
										<Col>
											<Form.Control
												type="text"
												onChange={(ev) => {
													onEntryChange(fieldArray?.profile_name?.value, ev.target.value);
												}}
												name={fieldArray?.profile_name?.value}
												defaultValue={queryParams?.profile_name}
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>

								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t(fieldArray?.frequency?.label)}
										</Form.Label>
										<Col>
											<Select
												tabIndex={8}
												isClearable={true}
												placeholder={t("Choose frequency")}
												classNamePrefix="react-select"
												className="align-middle"
												name="frequency"
												onChange={(ev) => {
													onEntryChange("frequency", ev?.value, ev?.value);
												}}

												defaultValue={() => setFrequencyOption()}
												options={INVOICE_REPEAT_CUSTOM_OPTION}
											></Select>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>

								</Col>
							</>
						)}
						{["organizations"].includes(search_model) && (
							<>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t("Organization id")}
										</Form.Label>
										<Col>
											<Form.Control
												type="text"
												onChange={(ev) => {
													onEntryChange("id_of_organization", ev.target.value);
												}}
												name={"id_of_organization"}
												defaultValue={queryParams?.id_of_organization}
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t("Organization name")}
										</Form.Label>
										<Col>
											<Form.Control
												type="text"
												onChange={(ev) => {
													onEntryChange("organization_name", ev.target.value);
												}}
												name={"organization_name"}
												defaultValue={queryParams?.organization_name}
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t("E-mail")}
										</Form.Label>
										<Col>
											<Form.Control
												type="text"
												onChange={(ev) => {
													onEntryChange("email_id", ev.target.value);
												}}
												name={"email_id"}
												defaultValue={queryParams?.email_id}
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t("Phone number")}
										</Form.Label>
										<Col>
											<Form.Control
												type="text"
												onChange={(ev) => {
													onEntryChange("phone_no", ev.target.value);
												}}
												name={"phone_no"}
												defaultValue={queryParams?.phone_no}
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t("Vat ID")}
										</Form.Label>
										<Col>
											<Form.Control
												type="text"
												onChange={(ev) => {
													onEntryChange("vat_id", ev.target.value);
												}}
												name={"vat_id"}
												defaultValue={queryParams?.vat_id}
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t("Created date range")}
										</Form.Label>
										<Col>
											<Row className="pb-0 coldshdiv">
												<Col xs={6}>
													<Form.Control
														ref={created_date_from_ref}
														type="date"
														name="created_date_from"
														defaultValue={queryParams?.created_date_from}
														onChange={(ev) => {
															onEntryChange("created_date_from", ev.target.value);
														}}
													/>
												</Col>
												<Col xs={6}>
													<Form.Control
														ref={created_date_to_ref}
														type="date"
														name="created_date_to"
														defaultValue={queryParams?.created_date_to}
														onChange={(ev) => {
															onEntryChange("created_date_to", ev.target.value);
														}}
													/>
												</Col>
											</Row>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t("Status")}
										</Form.Label>
										<Col>
											<Form.Select
												defaultValue={queryParams?.status}
												name="status"
												onChange={(ev) => {
													onEntryChange("status", ev.target.value);
												}}
											>
												<option value="">{t("Select")}</option>
												<option value="A">{t("Active")}</option>
												<option value="D">{t("Suspended")}</option>
											</Form.Select>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
							</>
						)}

						{["chartofaccounts"].includes(search_model) && (
							<>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t(fieldArray?.account_name?.label)}
										</Form.Label>
										<Col>
											<Form.Control
												type="text"
												onChange={(ev) => {
													onEntryChange(fieldArray?.account_name?.value, ev.target.value);
												}}
												name={fieldArray?.account_name?.value}
												defaultValue={queryParams?.account_name}
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t(fieldArray?.account_code?.label)}
										</Form.Label>
										<Col>
											<Form.Control
												type="text"
												defaultValue={queryParams?.account_code}
												name={fieldArray?.account_code?.value}
												onChange={(ev) => {
													onEntryChange(fieldArray?.account_code?.value, ev.target.value);
												}}
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t(fieldArray?.account_group?.label)}
										</Form.Label>
										<Col>
											<Select
												placeholder={t("Select")}
												name="account_group"
												isClearable={true}
												onChange={(ev) => {
													onEntryChange("account_group_name", ev?.label, ev?.value, "account_group");
												}}
												defaultValue={
													queryParams?.account_group_name ? { label: queryParams?.account_group_name, value: queryParams?.account_group } : null
												}
												options={account_groups_options}
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
							</>
						)}

						{/* Expenses and Paid Through Account */}
						{["expenses", "recurring-expenses"].includes(search_model) && (
							<>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t(fieldArray?.expenses_account_name?.label)}
										</Form.Label>
										<Col>
											<AccountsDropdown
												search_model={search_model} //type
												language={language}
												field_name="expense_account_id"
												selected_account_id={{
													label: queryParams["expenses_account_name"],
													value: queryParams["expense_account_id"],
												}}
												parentAccountCallback={(selectedObject) => {
													//parent callback
													onEntryChange("expenses_account_name", selectedObject?.label, selectedObject?.value, "expense_account_id");
												}}
												// exceptAccountIds={['624c165b734cf417f61ab74b']} //3rd lvl remove id (cash or cash equivalents)
												exceptAccountIds={[EXPENSE_ACCOUNTS_CONFIG?.exclude_accounts]} //3rd lvl remove id (cash or cash equivalents)
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>

								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t(fieldArray?.paid_through_account_name?.label)}
										</Form.Label>
										<Col>
											<AccountsDropdown
												search_model={search_model} //type
												language={language}
												field_name="paid_through_account_id"
												selected_account_id={{
													label: queryParams["paid_through_account_name"],
													value: queryParams["paid_through_account_id"],
												}}
												parentAccountCallback={(selectedObject) => {
													//parent callback
													onEntryChange("paid_through_account_name", selectedObject?.label, selectedObject?.value, "paid_through_account_id");
												}}
												// parentAccountIdForList={['624c165b734cf417f61ab74b', '624c1657734cf417f61ab699', '624c1657734cf417f61ab693']}
												parentAccountIdForList={EXPENSE_PAID_THROUGH_ACCOUNT_CONFIG?.in_accounts}
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
							</>
						)}
						{["items"].includes(search_model) && (
							<Col xs={12} md={6}>
								<Row>
									<Form.Label column sm={3}>
										{t(fieldArray?.item_input?.label)}
									</Form.Label>
									<Col>
										<Form.Control
											type="text"
											onChange={(ev) => {
												onEntryChange(fieldArray?.item_input?.value, ev.target.value);
											}}
											name={fieldArray?.item_input?.value}
											defaultValue={queryParams?.item_name}
										/>
										<div className="invalid-feedback"></div>
									</Col>
								</Row>
							</Col>
						)}
						{/* Customer vendor name input type */}
						{["customers", "vendors"].includes(search_model) && (
							<>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t(fieldArray?.contact_name?.label)}
										</Form.Label>
										<Col>
											<Form.Control
												type="text"
												onChange={(ev) => {
													onEntryChange(fieldArray?.contact_name?.value, ev.target.value);
												}}
												name={fieldArray?.contact_name?.value}
												defaultValue={queryParams?.contact_name}
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t(fieldArray?.contact_email?.label)}
										</Form.Label>
										<Col>
											<Form.Control
												type="text"
												defaultValue={queryParams?.email}
												name={fieldArray?.contact_email?.value}
												onChange={(ev) => {
													onEntryChange(fieldArray?.contact_email?.value, ev.target.value);
												}}
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
							</>
						)}
						{/* Transaction number */}
						{!["items", "customers", "vendors", "signup-request", "chartofaccounts", "organizations", "recurring-invoices", "recurring-bills", "recurring-journals", "recurring-expenses"].includes(search_model) && (
							<>
								{(
									<Col xs={12} md={6}>
										<Row>
											<Form.Label column sm={3}>
												{t(fieldArray?.number?.label)}#
											</Form.Label>
											<Col>
												<Form.Control
													type="text"
													onChange={(ev) => {
														onEntryChange(fieldArray?.number?.value, ev.target.value);
													}}
													name={fieldArray?.number?.value}
													defaultValue={queryParams?.transaction_number || queryParams?.file_name}
												/>
												<div className="invalid-feedback"></div>
											</Col>
										</Row>
									</Col>
								)}
								{/* All cases reference and date_range will exist  except documents & transfer orders*/}
								{/* {search_model !== "documents" && ( */}
								{!["documents", "transfer-orders","adjustments"].includes(search_model) && (
									<>
										<Col xs={12} md={6}>
											<Row>
												<Form.Label column sm={3}>
													{t(fieldArray?.reference?.label)}
												</Form.Label>
												<Col>
													<Form.Control
														type="text"
														defaultValue={queryParams?.order_number || queryParams?.reference || queryParams?.reference_number}
														name={fieldArray?.reference?.value}
														onChange={(ev) => {
															onEntryChange(fieldArray?.reference?.value, ev.target.value);
														}}
													/>
													<div className="invalid-feedback"></div>
												</Col>
											</Row>
										</Col>
										<Col xs={12} md={6}>
											<Row>
												<Form.Label column sm={3}>
													{t(fieldArray?.date_range?.label)}
												</Form.Label>
												<Col>
													<Row className="pb-0 coldshdiv">
														<Col xs={6}>
															<Form.Control
																ref={issue_date_from_ref}
																type="date"
																defaultValue={queryParams?.issue_date_from}
																name={fieldArray?.date_range?.value}
																onChange={(ev) => {
																	onEntryChange(fieldArray?.date_range?.value, ev.target.value);
																}}
															/>
														</Col>
														<Col xs={6}>
															<Form.Control
																ref={issue_date_to_ref}
																type="date"
																defaultValue={queryParams?.issue_date_to}
																name={fieldArray?.date_range?.value2}
																onChange={(ev) => {
																	onEntryChange(fieldArray?.date_range?.value2, ev.target.value);
																}}
															/>
														</Col>
													</Row>
													<div className="invalid-feedback"></div>
												</Col>
											</Row>
										</Col>
									</>
								)}
								{/* When case : document,there will be drop down for document type [all,image,pdf,docs,sheets] */}
								{search_model === "documents" && (
									<Col xs={12} md={6}>
										<Row>
											<Form.Label column sm={6}>
												{t(fieldArray?.reference?.label)}
											</Form.Label>
											<Col>
												<Form.Select
													defaultValue={queryParams?.document_type}
													name={fieldArray?.reference?.value}
													onChange={(ev) => {
														onEntryChange("document_type", ev.target.value);
													}}
												>
													{/* <option value="">{t('Select')}</option> */}
													<option value="all">{t("All")}</option>
													<option value="image">{t("Images")}</option>
													<option value="pdf">{t("PDF")}</option>
													<option value="docs">{t("Docs")}</option>
													<option value="sheets">{t("Sheets")}</option>
												</Form.Select>
												<div className="invalid-feedback"></div>
											</Col>
										</Row>
									</Col>
								)}
							</>
						)}
						{/* For created date range */}
						{!["chartofaccounts", "signup-request", "journal", "organizations", "recurring-invoices", "recurring-bills",].includes(search_model) && (
							<Col xs={12} md={6}>
								<Row>
									<Form.Label column sm={3}>
										{t(fieldArray?.created_date_range?.label)}
									</Form.Label>
									<Col>
										<Row className="pb-0 coldshdiv">
											<Col xs={6}>
												<Form.Control
													ref={created_date_from_ref}
													type="date"
													name={fieldArray?.created_date_range?.value}
													defaultValue={queryParams?.created_date_from}
													onChange={(ev) => {
														onEntryChange(fieldArray?.created_date_range?.value, ev.target.value);
													}}
												/>
											</Col>
											<Col xs={6}>
												<Form.Control
													ref={created_date_to_ref}
													type="date"
													name={fieldArray?.created_date_range?.value2}
													defaultValue={queryParams?.created_date_to}
													onChange={(ev) => {
														onEntryChange(fieldArray?.created_date_range?.value2, ev.target.value);
													}}
												/>
											</Col>
										</Row>
										<div className="invalid-feedback"></div>
									</Col>
								</Row>
							</Col>
						)}
						{["collections", "payments"].includes(search_model) && (
							<>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t("Payment mode")}
										</Form.Label>
										<Col>
											<Form.Select
												defaultValue={payment_modes?.find((mode) => mode._id === queryParams?.payment_mode)?._id}
												name="payment_mode"
												onChange={(ev) => {
													onEntryChange("payment_mode", ev.target.value);
												}}
											>
												<option value="">{t("Select")}</option>
												{payment_modes.map((mode) => (
													<option key={mode?._id} value={mode?._id}>
														{mode?.[language]}
													</option>
												))}
											</Form.Select>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t("Account")}
										</Form.Label>
										<Col>
											<AccountsDropdown
												search_model={search_model} //type
												language={language}
												field_name="deposit_to"
												// parentAccountIdForList={['624c165b734cf417f61ab74b']} //for list account id IMPORTANT
												parentAccountIdForList={[COLLECTION_ACCOUNTS_CONFIG?.in_accounts]} //for list account id IMPORTANT
												selected_account_id={{
													label: queryParams["deposit_account_name"],
													value: queryParams["deposit_to"],
												}}
												parentAccountCallback={(selectedObject) => {
													//parent callback
													onEntryChange("deposit_account_name", selectedObject?.label, selectedObject?.value, "deposit_to");
												}}
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
							</>
						)}
						{["items"].includes(search_model) && (
							<Col xs={12} md={6}>
								<Row>
									<Form.Label column sm={3}>
										{t(fieldArray?.item_type?.label)}
									</Form.Label>
									<Col>
										<Form.Select
											defaultValue={queryParams?.item_type}
											name={fieldArray?.item_type?.value}
											onChange={(ev) => {
												onEntryChange(fieldArray?.item_type?.value, ev.target.value);
											}}
										>
											<option value="">{t("Select")}</option>
											<option value="S">{t("Services")}</option>
											<option value="G">{t("Goods")}</option>
										</Form.Select>
										<div className="invalid-feedback"></div>
									</Col>
								</Row>
							</Col>
						)}
						{["invoices", "bills"].includes(search_model) && (
							<Col xs={12} md={6}>
								<Row>
									<Form.Label column sm={3}>
										{t(fieldArray?.payment_status?.label)}
									</Form.Label>
									<Col>
										<Form.Select
											defaultValue={queryParams?.payment_status}
											name={fieldArray?.payment_status?.value}
											onChange={(ev) => {
												onEntryChange(fieldArray?.payment_status?.value, ev.target.value);
											}}
										>
											<option value="">{t("Select")}</option>
											<option value="NP">{t("Not paid")}</option>
											<option value="PP">{t("Partially paid")}</option>
											<option value="FP">{t("Paid")}</option>
										</Form.Select>
										<div className="invalid-feedback"></div>
									</Col>
								</Row>
							</Col>
						)}
						{["credit-notes", "vendorcredits"].includes(search_model) && (
							<Col xs={12} md={6}>
								<Row>
									<Form.Label column sm={3}>
										{t(fieldArray?.status?.label)}
									</Form.Label>
									<Col>
										<Form.Select
											defaultValue={queryParams?.transaction_status}
											name={fieldArray?.status?.value}
											onChange={(ev) => {
												onEntryChange(fieldArray?.status?.value, ev.target.value);
											}}
										>
											<option value="">{t("Select")}</option>
											<option value="OP">{t("Open")}</option>
											<option value="DR">{t("Draft")}</option>
											<option value="CL">{t("Close")}</option>
										</Form.Select>
										<div className="invalid-feedback"></div>
									</Col>
								</Row>
							</Col>
						)}
						{/* Item dropdown list */}
						{/* {!['items', 'customers', 'vendors', 'collections', 'payments', 'chartofaccounts', 'journal', 'expenses','organizations'].includes(search_model) && ( */}
						{["invoices", "bills", "credit-notes", "vendorcredits", "transfer-orders", "adjustments"].includes(search_model) && (
							<Col xs={12} md={6}>
								<ItemDropdownForSearch
									labelName={"Item name"}
									search_model={search_model}
									language={language}
									queryParams={queryParams}
									parentItemSearchCallback={(field_name, field_value) => {
										//parent callback
										onEntryChange("item_name", field_name, field_value, "item_id");
									}}
								/>
							</Col>
						)}
						{/* Total range */}
						{!["customers", "signup-request", "vendors", "chartofaccounts", "journal", "organizations", "documents", "transfer-orders", "adjustments"].includes(search_model) && (
							<Col xs={12} md={6}>
								<Row>
									<Form.Label column sm={3}>
										{t(fieldArray?.total_range?.label)}
									</Form.Label>
									<Col>
										<Row className="pb-0 coldshdiv">
											<Col xs={6}>
												<Form.Control
													type="text"
													name={fieldArray?.total_range?.value}
													defaultValue={queryParams?.net_amount_from}
													onChange={(ev) => {
														onEntryChange(fieldArray?.total_range?.value, ev.target.value);
													}}
												/>
											</Col>
											<Col xs={6}>
												<Form.Control
													type="text"
													defaultValue={queryParams?.net_amount_to}
													name={fieldArray?.total_range?.value2}
													onChange={(ev) => {
														onEntryChange(fieldArray?.total_range?.value2, ev.target.value);
													}}
												/>
											</Col>
										</Row>
										<div className="invalid-feedback"></div>
									</Col>
								</Row>
							</Col>
						)}
						{/* customer drop down */}
						{!["items", "expenses", "signup-request", "customers", "vendors", "chartofaccounts", "organizations", "documents", "recurring-expenses", "transfer-orders", "adjustments"].includes(search_model) && (
							<Col xs={12} md={6}>
								{/* Contact dropdown */}
								<ContactDropdownForSearch
									labelName={fieldArray?.contact_name?.label}
									search_model={search_model}
									language={language}
									selected_contact_id={{
										label: queryParams["contact_name"],
										value: queryParams["customer_id"],
									}}
									parentContactCallback={(selectedObject) => {
										//parent callback
										onEntryChange("contact_name", selectedObject?.label, selectedObject?.value, "customer_id");
									}}

								// queryParams={queryParams}
								// parentContactCallback={(field_name, field_value, field_id) => {
								// 	//parent callback
								// 	onEntryChange(field_name, field_value, field_id, 'customer_id');
								// }}
								/>
							</Col>
						)}
						{["items"].includes(search_model) && (
							<>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t("Unit")}
										</Form.Label>
										<Col>
											<Form.Control
												type="text"
												onChange={(ev) => {
													onEntryChange("unit", ev.target.value);
												}}
												name="unit"
												defaultValue={queryParams?.unit}
												list={"item_units"}
											/>
											<EditableSelect id={"item_units"} list={unitList} />
											{/* <Form.Select
												className="dflt"
												value={queryParams?.unit}
												onChange={(ev)=>onEntryChange("unit", ev.target.value)}
											>
												<option value={''}>{t('Select')}</option>
												{unitList?.map((unit) => (
													<option key={unit?._id} value={unit[`${language}`]} >
														{unit[`${language}`]}
													</option>
												))}
											</Form.Select> */}
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t("Tax")}
										</Form.Label>
										<Col>

											<Select
												tabIndex={8}
												isClearable={true}
												placeholder={t("Choose tax")}
												classNamePrefix="react-select"
												className="align-middle"
												name="item_tax"
												onChange={(ev) => {
													onEntryChange("item_tax_category", ev?.value?.tax_category, ev?.value?.value, "item_tax");
												}}

												defaultValue={() => setTaxOption()}
												options={taxOptionsObject}
											></Select>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t('Sales account')}
										</Form.Label>
										<Col>
											<AccountsDropdown
												search_model={search_model} //type
												language={language}
												field_name="sales_account_id"
												selected_account_id={{
													label: queryParams["sales_account_name"],
													value: queryParams["sales_account_id"],
												}}
												parentAccountCallback={(selectedObject) => {
													//parent callback
													onEntryChange("sales_account_name", selectedObject?.label, selectedObject?.value, "sales_account_id");
												}}
												// parentAccountIdForList ={'624c165b734cf417f61ab74b'}
												parentAccountIdForList={SALES_ACCOUNTS_CONFIG?.in_accounts}
												// exceptAccountIds={['624c165b734cf417f61ab74b']} //3rd lvl remove id (cash or cash equivalents)
												exceptAccountIds={[SALES_ACCOUNTS_CONFIG?.exclude_accounts]} //3rd lvl remove id (cash or cash equivalents)
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t('Purchase account')}
										</Form.Label>
										<Col>
											<AccountsDropdown
												search_model={search_model} //type
												language={language}
												field_name="purchase_account_id"
												selected_account_id={{
													label: queryParams["purchase_account_name"],
													value: queryParams["purchase_account_id"],
												}}
												parentAccountCallback={(selectedObject) => {
													//parent callback
													onEntryChange("purchase_account_name", selectedObject?.label, selectedObject?.value, "purchase_account_id");
												}}
												parentAccountIdForList={PURCHASE_ACCOUNTS_CONFIG?.in_accounts}
												// exceptAccountIds={['624c165b734cf417f61ab74b']} //3rd lvl remove id (cash or cash equivalents)
												exceptAccountIds={[PURCHASE_ACCOUNTS_CONFIG?.exclude_accounts]} //3rd lvl remove id (cash or cash equivalents)
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
							</>
						)}
						{["customers"].includes(search_model) && (
							<Col xs={12} md={6}>
								<Row>
									<Form.Label column sm={3}>
										{t(fieldArray?.customer_type?.label)}
									</Form.Label>
									<Col>
										<Form.Select
											defaultValue={queryParams?.customer_type}
											name={fieldArray?.customer_type?.value}
											onChange={(ev) => {
												onEntryChange(fieldArray?.customer_type?.value, ev.target.value);
											}}
										>
											<option value="">{t("Select")}</option>
											<option value="I">{t("Individual")}</option>
											<option value="B">{t("Business")}</option>
										</Form.Select>
										<div className="invalid-feedback"></div>
									</Col>
								</Row>
							</Col>
						)}
						{["customers", "vendors"].includes(search_model) && (
							<>
								{/* <Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t('Contact')}
										</Form.Label>
										<Col>
											<Form.Control
												type="text"
												defaultValue={queryParams?.contact}
												name="contact"
												onChange={(ev) => {
													onEntryChange('contact', ev.target.value);
												}}
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col> */}
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t(fieldArray?.outstanding_range?.label)}
										</Form.Label>
										<Col>
											<Row className="pb-0 coldshdiv">
												<Col xs={6}>
													<Form.Control
														type="text"
														name={fieldArray?.outstanding_range?.value}
														defaultValue={queryParams?.outstanding_receivables_from || queryParams?.outstanding_payable_from}
														onChange={(ev) => {
															onEntryChange(fieldArray?.outstanding_range?.value, ev.target.value);
														}}
													/>
												</Col>
												<Col xs={6}>
													<Form.Control
														type="text"
														defaultValue={queryParams?.outstanding_payable_to || queryParams?.outstanding_receivables_to}
														name={fieldArray?.outstanding_range?.value2}
														onChange={(ev) => {
															onEntryChange(fieldArray?.outstanding_range?.value2, ev.target.value);
														}}
													/>
												</Col>
											</Row>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
							</>
						)}
						{["customers", "vendors", "collections", "payments"].includes(search_model) && (
							<Col xs={12} md={6}>
								<Row>
									<Form.Label column sm={3}>
										{t(fieldArray?.credits?.label)}
									</Form.Label>
									<Col>
										<Row className="pb-0 coldshdiv">
											<Col xs={6}>
												<Form.Control
													type="text"
													name={fieldArray?.credits?.value}
													defaultValue={queryParams?.balance_from}
													onChange={(ev) => {
														onEntryChange(fieldArray?.credits?.value, ev.target.value);
													}}
												/>
											</Col>
											<Col xs={6}>
												<Form.Control
													type="text"
													defaultValue={queryParams?.balance_to}
													name={fieldArray?.credits?.value2}
													onChange={(ev) => {
														onEntryChange(fieldArray?.credits?.value2, ev.target.value);
													}}
												/>
											</Col>
										</Row>
										<div className="invalid-feedback"></div>
									</Col>
								</Row>
							</Col>
						)}
						{/* For Journal account */}
						{["journal", "recurring-journals"].includes(search_model) && (
							<Col xs={12} md={6}>
								<Row>
									<Form.Label column sm={3}>
										{t("Account name")}
									</Form.Label>
									<Col>
										<AccountsDropdown
											search_model={search_model} //type
											language={language}
											field_name="account_id"
											selected_account_id={{
												label: queryParams["account_name"],
												value: queryParams["account_id"],
											}}
											parentAccountCallback={(selectedObject) => {
												//parent callback
												onEntryChange("account_name", selectedObject?.label, selectedObject?.value, "account_id");
											}}
										/>
										<div className="invalid-feedback"></div>
									</Col>
								</Row>
							</Col>
						)}

						{/* Expenses Vendor */}
						{["expenses", "recurring-expenses"].includes(search_model) && (
							<>
								<Col xs={12} md={6}>
									<ContactDropdownForSearch
										labelName="Vendor"
										search_model={search_model}
										language={language}
										selected_contact_id={{
											label: queryParams["vendor_name"],
											value: queryParams["vendor_id"],
										}}
										parentContactCallback={(selectedObject) => {
											//parent callback
											onEntryChange("vendor_name", selectedObject?.label, selectedObject?.value, "vendor_id");
										}}
										queryParams={queryParams}
									// parentContactCallback={(field_name, field_value, field_id) => {
									// 	//parent callback
									// 	onEntryChange(field_name, field_value, field_id, 'vendor_id');
									// }}
									/>
								</Col>
							</>
						)}
						{["invoices"].includes(search_model) && (
							<Col xs={12} md={6}>
								<Row>
									<Form.Label column sm={3}>
										{t("Sales person")}
									</Form.Label>
									<Col>
										<SalesPersonsDropdown
											showSalesPersonLabel={false}
											// selectSalesperson={salesPerson}
											searchPopup={true}
											parentSalesPersonChooseCallback={(selectedSalesPerson) => {
												//parent callback
												onEntryChange("sales_person_name", selectedSalesPerson?.label, selectedSalesPerson?.value, "sales_person_details");
											}}
											selectSalesperson={{
												label: queryParams["sales_person_name"],
												value: queryParams["sales_person_details"],
											}}
										/>
									</Col>
								</Row>
							</Col>
						)}
						{/* react select drop down for user list */}
						{["documents"].includes(search_model) && (
							<>
								<Col xs={12} md={6}>
									<UserDropdownForSearch
										labelName={fieldArray?.contact_name?.label}
										search_model={search_model}
										language={language}
										selected_user_id={{
											label: queryParams["user_name"],
											value: queryParams["user_id"],
										}}
										parentUserCallback={(selectedObject) => {
											//parent callback
											onEntryChange("user_name", selectedObject?.label, selectedObject?.value, "user_id");
										}}
									/>
									<div className="invalid-feedback"></div>
								</Col>
							</>
						)}
						{/* For transfer orders */}
						{["transfer-orders"].includes(search_model) && (
							<>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t(fieldArray?.transfer_date_range?.label)}
										</Form.Label>
										<Col>
											<Row className="pb-0 coldshdiv">
												<Col xs={6}>
													<Form.Control
														ref={transfer_date_from_ref}
														type="date"
														name={fieldArray?.transfer_date_range?.value}
														defaultValue={queryParams?.transfer_date_from}
														onChange={(ev) => {
															onEntryChange(fieldArray?.transfer_date_range?.value, ev.target.value);
														}}
													/>
												</Col>
												<Col xs={6}>
													<Form.Control
														ref={transfer_date_to_ref}
														type="date"
														name={fieldArray?.transfer_date_range?.value2}
														defaultValue={queryParams?.transfer_date_to}
														onChange={(ev) => {
															onEntryChange(fieldArray?.transfer_date_range?.value2, ev.target.value);
														}}
													/>
												</Col>
											</Row>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>
								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t(fieldArray?.status?.label)}
										</Form.Label>
										<Col>
											<Select
												tabIndex={8}
												isClearable={true}
												placeholder={t("Select status")}
												classNamePrefix="react-select"
												className="align-middle"
												name="transaction_status"
												onChange={(ev) => {
													onEntryChange("transaction_status", ev?.value);
												}}

												defaultValue={() => setTransactionStatusOption()}
												options={TRANSACTION_STATUS_OPTION}
											></Select>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>

								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t(fieldArray?.source_ware_house?.label)}
										</Form.Label>
										<Col>
											<Select
												tabIndex={8}
												isClearable={true}
												placeholder={t("Select source store")}
												classNamePrefix="react-select"
												className="align-middle"
												name="from_store_id"
												onChange={(ev) => {
													onEntryChange("from_store_name", ev?.label, ev?.value, "from_store_id");

												}}
												defaultValue={() => setSourceStoreOption()}
												options={stores_options?.source_warehouse}
											></Select>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>

								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t(fieldArray?.destination_ware_house?.label)}
										</Form.Label>
										<Col>
											<Select
												tabIndex={8}
												isClearable={true}
												placeholder={t("Select destination store")}
												classNamePrefix="react-select"
												className="align-middle"
												name="to_store_id"
												onChange={(ev) => {
													onEntryChange("to_store_name", ev?.label, ev?.value, "to_store_id");
												}}
												defaultValue={() => setDestinationStoreOption()}
												options={stores_options?.destination_warehouse}
											></Select>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>

								</Col>
								<Col xs={12} md={6}>
									<Row>
										<Form.Label column sm={3}>
											{t(fieldArray?.reason?.label)}
										</Form.Label>
										<Col>
											<Form.Control
												type="text"
												onChange={(ev) => {
													onEntryChange(fieldArray?.reason?.value, ev.target.value);
												}}
												name={fieldArray?.reason?.value}
												defaultValue={queryParams?.reason}
											/>
											<div className="invalid-feedback"></div>
										</Col>
									</Row>

								</Col>
							</>
						)}
						{/* For inventory adjustment */}
						{
							["adjustments"].includes(search_model) && (
								<>
									<Col xs={12} md={6}>
										<Row>
											<Form.Label column sm={3}>
												{t(fieldArray?.adjustment_date_range?.label)}
											</Form.Label>
											<Col>
												<Row className="pb-0 coldshdiv">
													<Col xs={6}>
														<Form.Control
															ref={adjustment_date_from_ref}
															type="date"
															name={fieldArray?.adjustment_date_range?.value}
															defaultValue={queryParams?.adjustment_date_from}
															onChange={(ev) => {
																onEntryChange(fieldArray?.adjustment_date_range?.value, ev.target.value);
															}}
														/>
													</Col>
													<Col xs={6}>
														<Form.Control
															ref={adjustment_date_to_ref}
															type="date"
															name={fieldArray?.adjustment_date_range?.value2}
															defaultValue={queryParams?.adjustment_date_to}
															onChange={(ev) => {
																onEntryChange(fieldArray?.adjustment_date_range?.value2, ev.target.value);
															}}
														/>
													</Col>
												</Row>
												<div className="invalid-feedback"></div>
											</Col>
										</Row>
									</Col>
									<Col xs={12} md={6}>
										<Row>
											<Form.Label column sm={3}>
												{t(fieldArray?.status?.label)}
											</Form.Label>
											<Col>
												<Select
													tabIndex={8}
													isClearable={true}
													placeholder={t("Select status")}
													classNamePrefix="react-select"
													className="align-middle"
													name="transaction_status"
													onChange={(ev) => {
														onEntryChange("transaction_status", ev?.value);
													}}

													defaultValue={() => setTransactionStatusOption()}
													options={TRANSACTION_STATUS_OPTION_FOR_INV_ADJUSTMENT.map(v=>({...v, label:t(v.label)}))}
												></Select>
												<div className="invalid-feedback"></div>
											</Col>
										</Row>

									</Col>
									<Col xs={12} md={6}>
										<Row>
											<Form.Label column sm={3}>
												{t(fieldArray?.adjustment_type?.label)}
											</Form.Label>
											<Col>
												<Select
													tabIndex={8}
													isClearable={true}
													placeholder={t("Select adjustment type")}
													classNamePrefix="react-select"
													className="align-middle"
													name="adjustment_type"
													onChange={(ev) => {
														onEntryChange("adjustment_type", ev?.value);
													}}

													defaultValue={() => setInventoryAdjustmentOption()}
													options={INVENTORY_ADJUSTMENT_TYPE.map(v=>({...v, label:t(v.label)}))}
												></Select>
												<div className="invalid-feedback"></div>
											</Col>
										</Row>

									</Col>
									<Col xs={12} md={6}>
										<Row>
											<Form.Label column sm={3}>
												{t(fieldArray?.source_ware_house?.label)}
											</Form.Label>
											<Col>
												<Select
													tabIndex={8}
													isClearable={true}
													placeholder={t("Select store")}
													classNamePrefix="react-select"
													className="align-middle"
													name="from_store_id"
													onChange={(ev) => {
														onEntryChange("from_store_name", ev?.label, ev?.value, "from_store_id");

													}}
													defaultValue={() => setSourceStoreOption()}
													options={stores_options?.source_warehouse}
												></Select>
												<div className="invalid-feedback"></div>
											</Col>
										</Row>

									</Col>
									<Col xs={12} md={6}>
										<Row>
											<Form.Label column sm={3}>
												{t(fieldArray?.reason?.label)}
											</Form.Label>
											<Col>
												<Select
													tabIndex={8}
													isClearable={true}
													placeholder={t("Select adjustment reason")}
													classNamePrefix="react-select"
													className="align-middle"
													name="reason"
													onChange={(ev) => {
														onEntryChange("reason", ev?.label, ev?.value, "reason_id");
													}}
													defaultValue={setInventoryAdjustmentReason}
													options={adjustment_reasons_RSelect}
												></Select>
												<div className="invalid-feedback"></div>
											</Col>
										</Row>

									</Col>
									<Col xs={12} md={6}>
										<Row>
											<Form.Label column sm={3}>
												{t(fieldArray?.branch?.label)}
											</Form.Label>
											<Col>
												<Select
													tabIndex={8}
													isClearable={true}
													placeholder={t("Select branch")}
													classNamePrefix="react-select"
													className="align-middle"
													name="branch_id"
													onChange={(ev) => {
														onEntryChange("branch", ev?.label, ev?.value, "branch_id");
													}}
													defaultValue={() => setDefaultBranchId()}
													options={branch_RSelect}
												></Select>
												<div className="invalid-feedback"></div>
											</Col>
										</Row>

									</Col>
								</>
							)
						}



					</Row>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					color="primary thm2"
					onClick={() => {
						createQueryString();
						handleSrchClose();
					}}
				>
					{t("Search")}
				</Button>
				<Button color="secondary" onClick={handleSrchClose}>
					{t("Close")}
				</Button>
			</Modal.Footer>
		</>
	);
};

//autosuggest and editable as well
const EditableSelect = ({ list, id }) => {
	const language = useSelector((state) => state.auth.language);

	return (
		<datalist id={id}>
			{list?.map((item) => (
				<option key={item?._id} value={item[`${language}`]} />
			))}
		</datalist>
	);
};

export default PopupSearchForm;
