import React from "react";
import NumberFormat from "react-number-format";
import { isNotEmpty } from "./helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { refreshOrUpdateContactBalance } from "../services/contact.service";
import { showAlert } from "./alertModal";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import "./../translations/i18n";
import { useTranslation } from "react-i18next";
import {Button} from "react-bootstrap";

/**
 * @desc This function is used to format number for display with customizations like prefix, suffix, decimal places, thousand separator
 * @param {Number} param0.value - value to be formatted like 1234.56
 * @param {Number} param0.defaultValue - default value to be formatted like 0
 * @param {Number} param.decimalScale - decimal places like 2 or 3
 * @param {Object} props - other props like thousandSeparator, prefix, suffix, negativeNumberInParenthesis, displayType, fixedDecimalScale, className, etc.
 * @returns
 */
const CustomNumberFormatForDisplay = ({ value, defaultValue, decimalScale = 2, ...props }) => {
	const { t } = useTranslation();
	// destructuring props to get the values
	const prefix_text = isNotEmpty(props?.prefix) ? props?.prefix?.trim() : ""; // remove any spaces from prefix
	const suffix_text = isNotEmpty(props?.suffix) ? props?.suffix?.trim() : ""; // remove any spaces from suffix
	const thousandSeparator = isNotEmpty(props?.thousandSeparator) ? props?.thousandSeparator : ","; // default is comma
	const negativeNumberInParenthesis = isNotEmpty(props?.negativeNumberInParenthesis) ? props?.negativeNumberInParenthesis : true; // if true then number will be in parenthesis, default is true
	const displayType = isNotEmpty(props?.displayType) ? props?.displayType : "text"; // default is text
	const fixedDecimalScale = isNotEmpty(props?.fixedDecimalScale) ? props?.fixedDecimalScale : true; // default is true
	const classNames = isNotEmpty(props?.className) ? props?.className : ""; //
	const style = isNotEmpty(props?.style) ? props?.style : {}; //

	// if negative number in parenthesis is true then return the number in parenthesis else return the number as it is
	const formatNumberForCustom = (number) => {
		// Check if the number is negative
		const isNegative = number?.replace(/[, ]+/g, "") < 0; // remove commas and spaces from the number for negative check
		let return_value = number; // return the number as it is if number is not negative
		// if negative number in parenthesis is true then return the number in parenthesis
		if (isNegative && negativeNumberInParenthesis) {
			const number_part = number?.split("."); // split the number with decimal
			const decimal_part = number_part[1] || 0; // get the decimal part if exists
			let integer_part = number_part[0]; // get the integer part
			integer_part = integer_part?.replace(/[^\d.-]/g, ""); // remove any non-numeric characters from the number for abs
			const abs_number = Math.abs(integer_part); // Convert the number to positive if it's negative
			let final_value = Number(abs_number)?.toLocaleString()?.replace(/[, ]+/g, thousandSeparator); // format the number with thousand separator
			final_value = final_value + "." + decimal_part?.toString(); // concat both parts with '.'
			return_value = `(${final_value})`; // return the number in parenthesis
		}

		return return_value;
	};
	const refreshContactBalance = async () => {
		try {
			const response = await refreshOrUpdateContactBalance({ contact_id: props?.contact_id });
			showAlert(response?.message, response?.status);
		} catch (e) {

			showAlert(e?.message, e?.status);
		}

	}
	return (
		<NumberFormat
			value={value}
			defaultValue={defaultValue}
			renderText={(formattedValue) => {
				const number = formatNumberForCustom(formattedValue);
				let final_value = prefix_text ? prefix_text + " " + number : number; // add prefix if it exists
				final_value = suffix_text ? final_value + suffix_text : final_value; // add suffix if it exists
				return (
					<span className={classNames} style={style}>
						{final_value} 
						{props?.showRefreshContactButton && (
							// <button className="btn btn-link txt-thm2 hvclr noline p-0 lh-1 m-0" ><FontAwesomeIcon icon={faSyncAlt} onClick={() => refreshContactBalance()} className="ml-5" />
							// </button></OverlayTrigger>)}
							<OverlayTrigger overlay={<Tooltip>{t('Click to re-calculate the outstanding balance')}</Tooltip>}><Button variant="link noline p-0 lh-1 vm f11x txt-gry ml-5" onClick={() => refreshContactBalance()}style={{marginTop:"-3px"}}><FontAwesomeIcon icon={faSyncAlt}/></Button></OverlayTrigger>)}
					</span>
				); // return the final value in span, for remain the same structure
			}}
			displayType={displayType}
			thousandSeparator={thousandSeparator}
			decimalScale={decimalScale}
			fixedDecimalScale={fixedDecimalScale}
			// className={classNames}
		/>
	);
};

export { CustomNumberFormatForDisplay };
