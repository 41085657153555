import React, { useState, useEffect } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
	userLanguage,
	isEmpty,
	//  systemAccounts,
	checkBoxhelper,
	showDateOrTimeFormat, JSXIf,
} from "../../util/helper";
import { dayCalculate } from "../../util/numberPad";
// import NumberFormat from 'react-number-format';
import * as Icon from "react-bootstrap-icons";
import moment from "moment";
import queryString from "query-string";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faFolderMinus, faDolly } from "@fortawesome/free-solid-svg-icons";
import SearchCriteriaBar from "../SearchCriteriaBar";
import SearchBox from "../SearchBox";
import { getTimeZone } from "../../services/API";
import { makeParentChildAccount } from "../../components/accounting/chartOfAccounts/chart_of_account_tree";
import { CustomNumberFormatForDisplay } from "../../util/CustomNumberFormat";

//this component is used to create search form And all list page for each model
/**
 *
 * @param {"items" | "customers" | "invoices" | "proforma_invoices" | "bills" | "expenses"| "adjustments" | "reports" } listType
 * @param {object[]} allListData
 * @param {function} t
 * @param {"search" | null } callType
 * @name parentCallbackForSearch
 * @returns
 */
const ListPage = ({
	listType, //proforma_invoices, invoice
	allListData,
	t,
	callType = "",
	parentCallbackForSearch,
	searchElementParams, //only pass when search from top search box
}) => {
	let { id } = useParams();
	const history = useHistory();
	let { search } = useLocation();
	let currentDate = moment().utcOffset(getTimeZone).format("YYYY-MM-DD"); //current date
	const language = useSelector((state) => state.auth.language);
	const fieldLanguage = userLanguage(language);
	const payment_modes = useSelector((state) => state.auth.userInfo.default_organization_id.payment_modes);
	const chart_of_account = useSelector((state) => state.auth.userInfo.chart_of_account);
	const { system_defaults: SYSTEM_DEFAULT_ACCOUNTS_CONFIG } = useSelector((state) => state.auth.userInfo.account_settings ?? {});
	const [clickedIndex, setClickedIndex] = useState([]); //for check and uncheck
	const [listingData, setListingData] = useState([]); //set data

	useEffect(() => {
		if ("chartofaccounts" === listType && "search" !== callType) {
			generatchartofaccount();
		} else {
			setListingData(allListData);
		}
	}, [allListData]);

	// check overdue
	const checkOverDue = (due_date) => {
		return moment(currentDate).isAfter(moment(due_date).utcOffset(getTimeZone).format("YYYY-MM-DD"), "day");
	};

	const setCalss = "search" === callType ? "list_table" : "invLst"; //for css condition
	const amountData = ["collections", "payments"].includes(listType) ? "amount_recived" : "net_amount"; //'amount_recived' for 'collections' and 'payments', 'net_amount' for invoices, credit notes, vendor credit, bill
	const order_number = "credit-notes" === listType ? "reference" : "order_number";

	const [srchshow, setsrchShow] = useState(false);
	const handleSrchClose = () => setsrchShow(false);
	const handleSrchShow = () => setsrchShow(true);

	let params = queryString.parse(search); //search query string



	//This section used to load particular item when onclick
	const ListPreview = (invoiceId) => {
		if ("search" === callType) {
			parentCallbackForSearch();
			//concat with search query
			// if (search) {
			// 	search += `${searchElementParams}`; //concat with search query
			// } else {
			// 	search = `?${searchElementParams}`; //concat with search query
			// }
			search = `?${searchElementParams}`; //concat with search query
			//Only for reset the pagination page no 1 when click on any item from search dropdown
			let query_object = queryString.parse(search); //parse the query string
			query_object.page = 1; //reset page to 1
			search = "?" + queryString.stringify(query_object); //convert the object to query string
		}

		//different push path for chart of account and journals
		if ("chartofaccounts" === listType) {
			history.push(`/${language}/accounting/${listType}/details/${invoiceId}${search}`);
		} else if ("journal" === listType) {
			history.push(`/${language}/accounting/journals/details/${invoiceId}${search}`);
		} else if ("organizations" === listType) {
			history.push(`/${language}/organizations-list/${search}`);
		} else if ("reports" === listType) {
			history.push(`/${language}/reports/${invoiceId}${search}`); //change url
		} else if ("transfer-orders" === listType) {
			history.push(`/${language}/inventory/transfer_orders/details/${invoiceId}${search}`); //change url
		}
		 else if ("adjustments" === listType) {
			history.push(`/${language}/inventory/adjustments/details/${invoiceId}${search}`); //change url
		}
		else {
			history.push(`/${language}/${listType}/details/${invoiceId}${search}`); //change url
		}
	};

	const checkBoxhandleChange = (index) => (e) => {
		let checkedIndex = [...clickedIndex]; // all checked index state
		checkedIndex.push(index); // index push in the array when click one by one
		setClickedIndex(checkedIndex);
		let result = checkBoxhelper(e, checkedIndex, listingData);
		setListingData(result);
	};

	const showStraightLine = (depth, condition, left = 0, child) => {
		let returnLine = {};
		let leftValue = left;
		if (depth > 3 && "S" === condition) {
			returnLine = {
				position: "relative",
				width: "20px",
				insetInlineStart: leftValue + "px",
				bottom: "10px",
				paddingTop: "20px",
				paddingBottom: "12px",
				borderInlineStart: "1px solid #adadad",
			};
		} else if (depth > 2 && "L" === condition) {
			returnLine = {
				position: "relative",
				insetInlineEnd: "-9px",
				bottom: "10px",
				paddingTop: "20px",
				borderInlineStart: "1px solid #adadad",
				borderBottom: "1px solid #adadad",
			};
		}
		return returnLine;
	};
	const space = (count) => {
		let html = "";
		for (let i = 0; i < count; i++) {
			html += "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;";
		}
		return html;
	};
	let html = [];
	function generateAccountOption(data, i = 0) {
		i++;
		data?.map((e) => {
			e.depths = i;
			if (e.children?.length > 0) {
				e.sub = true;
			}
			html.push(e);
			if (e.children?.length) {
				generateAccountOption(e?.children, i);
			}
		});
		return html;
	}
	const generatchartofaccount = async () => {
		// let chart_of_accounts = chart_of_account.filter((acc) => acc?.depth > 1)
		let chart_of_account_Tree = await makeParentChildAccount(chart_of_account, undefined, language);
		let optionsData = generateAccountOption(chart_of_account_Tree);
		optionsData = optionsData.filter((acc) => acc?.depth > 1);
		setListingData(optionsData); //set parent account options
	};


	return (
		<>
			{params?.search && "search" !== callType && (
				<div className="lstsrch">
					<SearchCriteriaBar onSearchShow={handleSrchShow} queryParams={params} search_model={listType} />
				</div>
			)}
			<table className={`w-100 overflow-hidden ${setCalss}`} id="searchListTable">
				<tbody>
					{listingData &&
						listingData?.map((listData, index) => (
							<tr key={index} className={id === listData._id ? "active" : ""}>
								{"search" !== callType && (
									<td>
										{SYSTEM_DEFAULT_ACCOUNTS_CONFIG && Object.values(SYSTEM_DEFAULT_ACCOUNTS_CONFIG)?.includes(listData?._id) ? (
											//for chart of account system account lock symbol
											<FontAwesomeIcon icon={faLock} style={{ color: "#afafaf" }} />
										) : (
											<Form.Check
												type="checkbox"
												name={listData?._id}
												checked={listData?.isChecked || false}
												onChange={checkBoxhandleChange(index)}
											/>
										)}
									</td>
								)}

								<td onClick={() => ListPreview(listData._id)} className={"chartofaccounts" === listType ? "step text-nowrap" : ""}>
									<div className={"chartofaccounts" === listType ? "" : "lst-pmr d-flex justify-content-between align-items-center fw-6"}>
										{/* For Item list */}
										{"items" === listType && (
											<span className="fw-6">
												{listData?.item_name?.[fieldLanguage]}
												{listData?.is_inventory_item && (
													<FontAwesomeIcon icon={faDolly} className="txt-thm1 mb-n2x ml-5 csrpnt" title={t("Inventory item")} />
												)}
											</span>
										)}
										{/* For organization search */}
										{"organizations" === listType && <span className="fw-6">{listData?.organization_name?.[fieldLanguage]}</span>}
										{/* For Document list */}
										{"documents" === listType && <span>{listData?.document_name}</span>}
										{/*for chart of account name */}
										{"chartofaccounts" === listType && "search" !== callType && (
											// <span>
											// 	<span className="inblk">{listData?.account_code ? listData?.account_code + ' - ' : ''}</span>
											// 	<span className="inblk" title={listData?.title?.[fieldLanguage]}>
											// 		{listData?.title?.[fieldLanguage]}
											// 	</span>
											// </span>
											<React.Fragment>
												{
													<span
														style={showStraightLine(listData.depth, "S", 52)}
														dangerouslySetInnerHTML={{
															__html: space(listData.depth - 1),
														}}
													/>
												}
												{listData.depth === 5 && <span className="dpth5" style={showStraightLine(listData.depth, "S", -13)} />}
												{listData.depth === 6 && <span className="dpth6" style={showStraightLine(listData.depth, "S", -34)} />}
												{
													<span className="ownline" style={showStraightLine(listData.depth, "L", "", listData?.children)}>
														&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
													</span>
												}
												<span className="linkcolor text-nowrap">
													{listData?.children && (
														<FontAwesomeIcon icon={faFolderMinus} style={{ color: "#afafaf", fontSize: "1rem", transform: "translateY(-1.5px)" }} />
													)}
													<span style={{ marginInlineStart: "15px", position: "relative", zIndex: "10" }}>{listData?.title?.[fieldLanguage]}</span>
												</span>
											</React.Fragment>
										)}
										{"chartofaccounts" === listType && "search" === callType && (
											<span>
												<span className="inblk">{listData?.account_code ? listData?.account_code + " - " : ""}</span>
												<span className="inblk" title={listData?.title?.[fieldLanguage]}>
													{listData?.title?.[fieldLanguage]}
												</span>
											</span>
										)}
										{/* For customer and vendor list */}
										{["customers", "vendors"].includes(listType) &&
											<><span>{listData?.contact_name?.[fieldLanguage]}</span>
												<div className="f11x mt-1 fw-5 txt-gry2 mb-n5x"><em>{listData?.transaction_number}</em></div></>
										}
										{![
											"customers",
											"vendors",
											"items",
											"documents",
											"journal",
											"chartofaccounts",
											"reports",
											"adjustments",
											"transfer-orders",
											"adjustments",
										].includes(listType) && (
											<>
												<span>
													{/* Only for expenses */}
													{("expenses" === listType) &&
														(listData?.item_list?.length > 1
															? "Split"
															: listData?.item_list?.[0]?.expense_account_id && listData?.item_list?.[0]?.expense_account_id?.title?.[fieldLanguage])}
													{/* For all other pages */}
													{"expenses" !== listType && listData?.contact_id?.contact_name?.[fieldLanguage]}
												</span>
												<samp className="text-nowrap">
													{listData?.attachments?.length > 0 && (
														<span className="d-inline-block mr-5">
															<Icon.Paperclip className="mt-n3x" />
														</span>
													)}
													<CustomNumberFormatForDisplay
														value={listData[amountData]}
														prefix={listData?.contact_id?.currency ? listData?.contact_id?.currency : listData?.currency ? listData?.currency : "SAR"}
														className="d-inline-block"
													/>
												</samp>
											</>
										)}
										{["journal"].includes(listType) && (
											<>
												<span>{listData?.transaction_number}</span>
												<span className="text-nowrap">
													<CustomNumberFormatForDisplay
														value={Math.max(listData.total_debit_amount, listData.total_credit_amount)}
														prefix={listData?.currency ?? "SAR"}
														className="d-inline-block"
														displayType={"text"}
														thousandSeparator={true}
														decimalScale={2}
														fixedDecimalScale={true}
													/>
												</span>
											</>
										)}
										{["transfer-orders","adjustments"].includes(listType) && (
											<>
												<span className="linkcolor">{listData?.transaction_number}</span>
												<span className="text-nowrap">
													<CustomNumberFormatForDisplay value={listData?.total_transfer_quantity} />
												</span>
											</>
										)}
										{["reports"].includes(listType) && (
											<>
												<span>{t(listData?.title)}</span>
											</>
										)}
									</div>
									<div className="lst-sdr">
										{/* For Document list */}
										{"documents" === listType && (
											<div className="row1 d-flex justify-content-between mt-2">
												<div>
													<span className="p-0">
														<bdo dir="ltr">{moment(listData?.createdAt).format("DD MMM YYYY")}</bdo>
													</span>
												</div>
											</div>
										)}
										{/* For customer and vendor list */}
										{["customers", "vendors"].includes(listType) && (
											<div className="row1 d-flex justify-content-between mt-2">
												<CustomNumberFormatForDisplay
													value={listType === "customers" ? listData?.outstanding_receivables ?? 0 : listData?.outstanding_payable ?? 0}
													prefix={listData?.currency}
													className="p-0"
													style={{ fontSize: "0.739rem" }}
													contact_id={listData?._id}
												/>
											</div>
										)}
										{/* This block for invoice, credit note, proforma invoices, bills, vendor credits */}
										{/* {!['collections', 'payments', 'customers', 'vendors', 'items', 'documents', 'chartofaccounts', 'expenses'].includes(listType) && ( */}
										{[
											"invoices",
											"proforma_invoices",
											"bills",
											"credit-notes",
											"vendorcredits",
											"journal",
											"salesorders",
											"purchaseorders",
											"adjustments",
										].includes(listType) && (
											<>
												<div className="row1 d-flex justify-content-between mt-2">
													<div>

														{JSXIf({
															default_element: <span>{listData?.transaction_number}</span>,
															conditions: [
																{
																	is_true: "adjustments" === listType,
																	element: <span className={"linkcolor"}>{listData.reason?.[language]}</span>,
																},
															],
														})}

														{/* <span>{moment(listData?.issue_date).utcOffset(getTimeZone ?? "+00.00").format('DD MMM YYYY')}</span> @asif8-2-2023*/}
														<span>
															{/* {moment(listData?.issue_date).format('DD MMM YYYY')} */}
															{/* <bdo dir='ltr'>{['invoices','proforma_invoices','credit-notes'].includes(listType) ? moment(listData?.issue_date).format('DD MMM YYYY'):moment(listData?.issue_date).utcOffset(getTimeZone ?? "+00.00").format('DD MMM YYYY')}</bdo> */}
															<bdo dir="ltr">{showDateOrTimeFormat(listData?.issue_date, "", false)}</bdo>
														</span>
													</div>
													{/* Block for status of each module */}
													{/* For invoice module, proforma invoice */}
													{["invoices", "proforma_invoices"].includes(listType) &&
														(listType === "proforma_invoices" && (listData?.invoices ?? []).length > 0 ? (
															<aside className="invsts gren">{t("Invoiced")}</aside>
														) : listData?.transaction_status === "DR" ? (
															<aside className="invsts">{t("Draft")}</aside>
														) : listData?.transaction_status === "V" ? (
															<aside className="invsts">{t("Void")}</aside>
														) : listData?.payment_status === "FP" ? (
															<aside className="invsts gren">{t("Paid")}</aside>
														) : listData?.payment_status !== "FP" &&
														  listData?.transaction_status === "ST" &&
														  checkOverDue(listData?.due_date || listData?.expiry_date) ? (
															<aside className="invsts red">
																{listData?.due_date && t("Overdue by") + " " + dayCalculate(listData?.due_date, t)}
																{listData?.expiry_date && t("Expired by") + " " + dayCalculate(listData?.expiry_date, t)}
															</aside>
														) : listData?.transaction_status === "CN" ? (
															<aside className="invsts blue">{t("Confirmed")}</aside>
														) : (
															<aside className="invsts blue">{t("Sent")}</aside>
														))}
													{/* For sales order */}
													{["salesorders", "purchaseorders"].includes(listType) &&
														(listData?.transaction_status === "DR" ? (
															<aside className="invsts">{t("Draft")}</aside>
														) : listData?.transaction_status === "V" ? (
															<aside className="invsts">{t("Void")}</aside>
														) : listData?.transaction_status === "CL" ? (
															<aside className="invsts gren">{t("Closed")}</aside>
														) : listData?.transaction_status === "CN" && checkOverDue(listData?.expiry_date) ? (
															<aside className="invsts red">
																{t("Expired by")} {dayCalculate(listData?.expiry_date, t)}
															</aside>
														) : (
															<aside className="invsts blue">{t("Confirmed")}</aside>
														))}
													{/* For Bill module */}
													{["bills"].includes(listType) &&
														(listData?.transaction_status === "DR" ? (
															<aside className="invsts">{t("Draft")}</aside>
														) : listData?.transaction_status === "V" ? (
															<aside className="invsts">{t("Void")}</aside>
														) : listData?.payment_status === "FP" ? (
															<aside className="invsts gren">{t("Paid")}</aside>
														) : listData?.payment_status !== "FP" && listData?.transaction_status === "OP" && checkOverDue(listData?.due_date) ? (
															<aside className="invsts red">
																{t("Overdue by")} {dayCalculate(listData?.due_date, t)}
															</aside>
														) : (
															<aside className="invsts blue">{t("Open")}</aside>
														))}

													{/* For Credit note module */}
													{["credit-notes", "vendorcredits"].includes(listType) &&
														(listData?.transaction_status === "DR" ? (
															<aside className="invsts">{t("Draft")}</aside>
														) : listData?.transaction_status === "V" ? (
															<aside className="invsts">{t("Void")}</aside>
														) : listData?.transaction_status === "OP" ? (
															<aside className="invsts blue">{t("Open")}</aside>
														) : listData?.transaction_status === "CL" ? (
															<aside className="invsts gren">{t("Close")}</aside>
														) : (
															<aside className="invsts gren">{t("Sent")}</aside>
														))}
													{/* For journal module */}
													{["journal"].includes(listType) &&
														(listData?.journal_status === "PB" ? (
															<aside className="invsts gren">{t("Published")}</aside>
														) : (
															<aside className="invsts">{t("Draft")}</aside>
														))}

													{/* For transfer order module */}
													{["transfer-orders"].includes(listType) &&
														(listData?.transaction_status === "DR" ? (
															<aside className="invsts">{t("Draft")}</aside>
														) : listData?.transaction_status === "V" ? (
															<aside className="invsts">{t("Void")}</aside>
														) : listData?.transaction_status === "IN_TRANSIT" ? (
															<aside className="invsts blue">{t("In transit")}</aside>
														) : listData?.transaction_status === "TRANSFERRED" ? (
															<aside className="invsts gren">{t("Transferred")}</aside>
														) : (
															<aside className="invsts">{t("Draft")}</aside>
														))}

													{/* FOR Recurring invoices,bills,journals, transaction status*/}
													{["recurring-invoices", "recurring-bills","recurring-journals"].includes(listType) &&
														(listData?.transaction_status === "active" ? (
															<aside className="invsts gren">{t("Active")}</aside>
														) : listData?.transaction_status === "stopped" ? (
															<aside className="invsts red">{t("Stopped")}</aside>
														) : (
															<aside className="invsts blue">{t("Expired")}</aside>
														))}
													{/* For inventory adjustment module */}
													{["adjustments"].includes(listType) &&
														(listData?.transaction_status === "AD" ? (
															<aside className="invsts gren">{t("Adjusted")}</aside>
														) : (
															<aside className="invsts">{t("Draft")}</aside>
														))}
												</div>
												<div className="row2 mt-2">
													<span>{listData?.[order_number]} </span>
												</div>
											</>
										)}
										{/* This block only for expenses date, vendor name, amount, status */}
										{["expenses"].includes(listType) && (
											<>
												<div className="row1 d-flex justify-content-between mt-2">
													<div>
														<span
															className="">{listData?.transaction_number}
														</span>
														<span>
															<bdo
																dir="ltr">{moment(listData?.issue_date).format("DD MMM YYYY")}</bdo>
														</span>
														{listData?.vendor_id?._id &&
															<span>{listData?.vendor_id?.contact_name?.[fieldLanguage]}</span>}
													</div>
													{/* <aside className="invsts">Unbilled</aside> */}
												</div>
												{listData?.reference && (
													<div className="row2 mt-2">
														<span>{listData?.reference}</span>
													</div>
												)}
											</>
										)}
										{/* This block for collection and payment */}
										{["collections", "payments"].includes(listType) && (
											<>
												<div className="row1 d-flex justify-content-between mt-1 pt-1">
													<div>
														<span className="linkcolor">{listData?.transaction_number}</span>
														<span>
															<bdo dir="ltr">{moment(listData?.issue_date).format("DD MMM YYYY")}</bdo>
														</span>
													</div>
													<aside>
														{listData?.payment_mode &&
															!isEmpty(payment_modes) &&
															payment_modes?.findIndex((e) => e._id === listData?.payment_mode) >= 0 &&
															payment_modes?.find((e) => e._id === listData?.payment_mode)[language]}
													</aside>
												</div>
												<div className="row2 mt-2 d-flex justify-content-between">
													<span>
														{listData?.applied_transactions
															?.filter((amount) => amount?.amount > 0)
															?.map(({ transaction_id }) =>
																transaction_id?.opening_balance ? t("Opening balance") : transaction_id?.transaction_number
															)
															.join(", ")}
													</span>
													{listType === "collections" && <span>{t("Invoice payment")}</span>}
													{listType === "payments" && <span>{t("Bill payment")}</span>}
												</div>
											</>
										)}

										{["transfer-orders"].includes(listType) && (
											<>
												<div className="row1 d-flex justify-content-between mt-2">
													<div>
														<bdo dir="ltr">{showDateOrTimeFormat(listData?.issue_date, "", false)}</bdo>
													</div>

													{/* For transfer order module */}
													{["transfer-orders"].includes(listType) &&
														(listData?.transaction_status === "DR" ? (
															<aside className="invsts">{t("Draft")}</aside>
														) : listData?.transaction_status === "V" ? (
															<aside className="invsts">{t("Void")}</aside>
														) : listData?.transaction_status === "IN_TRANSIT" ? (
															<aside className="invsts blue">{t("In transit")}</aside>
														) : listData?.transaction_status === "TRANSFERRED" ? (
															<aside className="invsts gren">{t("Transferred")}</aside>
														) : (
															<aside className="invsts">{t("Draft")}</aside>
														))}
												</div>
												{/* show from and to store  */}
												<div className="row2 mt-2">
													<span>
														<samp className="d-inline-block">{listData?.from_store_details?.store_name?.[language]}</samp>{" "}
														{"en" === language ? "→" : "←"}{" "}
														<samp className="d-inline-block">{listData?.to_store_details?.store_name?.[language]}</samp>
													</span>
												</div>
											</>
										)}
									</div>
								</td>
							</tr>
						))}
					{"search" === callType && allListData?.length === 0 && (
						<tr>
							<td className="text-center">{t("No result found")}</td>
						</tr>
					)}
				</tbody>
			</table>

			{srchshow && "search" !== callType && (
				<SearchBox srchshow={srchshow} handleSrchClose={handleSrchClose} queryParams={params} search_model={listType} />
			)}
		</>
	);
};
export default ListPage;
