import { secureAPI, responseAPI } from "../services/API";
import { checkTokenValidate } from "./../util/helper";
import moment from "moment";

const CURRENCY_BASE_PATH = "/organizations/currencies";

/*Organization  service
Store all organizations route api call here.
*/

/*
create a organization of a user
return promise
resolve : object
*/
async function createNewOrganization(org_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post("/organizations", org_payload)
			.then(({ data }) => {
				if (data.success) {
					data.data[0].token = data.token;
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

/*
get a organization of a user
return promise
resolve : object
*/
async function getAnOrganization(org_id) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/organizations/${org_id}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function updateAOrganization(org_id, org_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/organizations/${org_id}`, org_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function patchOrganization(org_id, thm_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/organizations/${org_id}`, thm_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function deleteAnOrganization(org_id) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.delete(`/organizations/${org_id}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

///unit paymentmode service------------------------------------------------------

async function getAllCommonItems(type) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/organizations/common_items/${type}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function getAnCommonItems(type, id) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/organizations/common_items/${type}/${id}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function createCommonItems(type, body) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post(`/organizations/common_items/${type}`, body)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function updateCommonItems(type, id, body) {
	return responseAPI(`/organizations/common_items/${type}/${id}`, body, "data");
	// return new Promise((resolve, reject) => {
	// 	secureAPI()
	// 		.put(`/organizations/common_items/${type}/${id}`, body)
	// 		// .responseAPI(resolve, reject, { data });
	// 		.then(({ data }) => {
	// 			if (data.success) {
	// 				resolve(data.data); //return array
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			reject(err.response.data.error);
	// 		});
	// });
}

//for unit create
/**
 * @method: POST
 * @param {*} body
 * @returns
 * @description: create unit
 * @route: /organizations/units/
 * */
async function createUnit(body) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post(`/organizations/units/`, body)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function getAllUnits(selectElement = null, searchParam = "") {
	let url = "/organizations/units?status=A" + searchParam;
	if (selectElement) {
		url = `${url}&select=${selectElement}`;
	}
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(url)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function getAnUnit(unit_id, selectElement = null) {
	let url = `/organizations/units/${unit_id}`;
	if (selectElement) {
		url = `${url}?select=${selectElement}`;
	}
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(url)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function updateUnit(unit_id, unitPayload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/organizations/units/${unit_id}`, unitPayload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function deleteUnit(unit_id) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.delete(`/organizations/units/${unit_id}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function deleteCommonItems(type, id) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.delete(`/organizations/common_items/${type}/${id}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

//get exchage rates of a curreny
//if "effective_date" is present use that as filter
async function getExchangeRates(currency_id, effective_date) {
	let query = "";
	if (effective_date) {
		query = `?of_date=${effective_date}`;
	} else {
		query = `?today=${moment().format("YYYY-MM-DD")}`;
	}
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/organizations/currencies/${currency_id}/exchangerates${query}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

/**
 * @fetchExchangeRateBasedOnDate fetch exchange rate based ib currency and date
 * @parmas selected_date, currency id
 */
async function fetchExchangeRateBasedOnDate(currency_id, selected_date) {
	let query = "";
	if (selected_date) {
		query = `?for_date=${selected_date}`;
	}
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/organizations/currencies/${currency_id}/exchangerates${query}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

//create an exchage rate of a currency
//if a rate on a date is already found it will update the rate only
async function createExchangeRates(currency_id, payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post(`/organizations/currencies/${currency_id}/exchangerates`, payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

//delete an exchage rate of a currency
async function deleteExchangeRates(currency_id, erate_id) {
	let query = `?today=${moment().format("YYYY-MM-DD")}`;
	return new Promise((resolve, reject) => {
		secureAPI()
			.delete(`/organizations/currencies/${currency_id}/exchangerates/${erate_id}${query}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

/*
get all organizations
*/
//extra , fillter as array
async function getAllOrganization(selectElement = null, searchParam = "") {
	let url = "/organizations";

	// Check if status is already in searchParam
	if (!searchParam.includes("status=")) {
		url += "?status=A";
	} else {
		url += "?";
	}

	// Append other search parameters if present
	url += searchParam;

	if (selectElement) {
		// Add select parameter with a separator
		url += (url.includes("=") ? "&" : "") + `select=${selectElement}`;
	}

	return new Promise((resolve, reject) => {
		secureAPI()
			.get(url)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); // Return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response?.data?.error);
			});
	});
}

async function getOrgTransactionHistory(org_id) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/organizations/transactions/${org_id}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

//Service Name	: addUpdateOrgTransactionLock
//Author		: Debaprasad Maity
//Purpose		: make transaction lock
//Date			: 08-12-2022
async function addUpdateOrgTransactionLock(transactionLockPayload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post("/organizations/transaction_lock", transactionLockPayload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				//console.log(err.response)
				checkTokenValidate(err);
				reject(err.response);
				// reject(err.response);
			});
	});
}

//Service Name	: getTransactionLock
//Autjor 		: Debaprasad Maity
//purpose		: retrive transaction lock
//Date			: 08-12-2022
async function getTransactionLock() {
	return new Promise((resolve, reject) => {
		secureAPI()
			.get("/organizations/common_items/transaction_lock")
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

// get tax return settings
async function getTaxReturnSettings() {
	return new Promise((resolve, reject) => {
		secureAPI()
			.get("/organizations/tax_settings")
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function updateTaxReturnSettings(tax_settings_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.put("/organizations/tax_settings", tax_settings_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
//organization Currency
/**
 * @method: GET
 * @param {*} selectElement
 * @param {*} searchParam
 * @returns
 */
async function getAllCurrency(selectElement = null, searchParam = "") {
	let query = `?today=${moment().format("YYYY-MM-DD")}`;
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/organizations/currencies${query}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

/**
 * @method:POST
 * @param {*} payload
 * @returns
 */
async function createCurrency(payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post("/organizations/currencies", payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

/**
 * @method: PUT
 * @param {*} payload
 * @returns
 */
async function updateCurrency(currency_id, payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/organizations/currencies/${currency_id}`, payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
/**
 * @method: PUT set organization wise contact sequence number format 
 * @param {*} payload
 * @returns
 */
async function updateContactSequenceNumberFormat(payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/organizations/contact_sequence_format`, payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
/**
 * @method: GET a organization wise contact sequence number format 
 * @param {*} payload
 * @returns
 */
async function getContactSequenceNumber(payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/organizations/contact_sequence_format`, payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

// add already existing account to a custom list that will be shown as deposit to account
// when creating payment or collection
async function setPaymentAccounts(payment_accounts_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post("/organizations/payment_accounts", payment_accounts_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

// get payment accounts
async function getPaymentAccounts() {
	return new Promise((resolve, reject) => {
		secureAPI()
			.get("/organizations/payment_accounts")
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

// remove payment accounts (also remove children under this account)
async function removePaymentAccount(payment_account_id) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/organizations/payment_accounts/${payment_account_id}/remove`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

//enable / disable api access for an organization
async function setOrganizationConfig(payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post(`organizations/features_config`, payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

/**
 * @method: DELETE
 * @path: /organizations/currencies/:id
 * @param {string} currency_id
 */
async function deleteCurrency({ currency_id }) {
	try {
		const response_data = await secureAPI().delete(`${CURRENCY_BASE_PATH}/${currency_id}`);
		const { data } = response_data;
		if (data.success) {
			return data;
		}
	} catch (error) {
		throw error.response.data.error;
	}
}

// Store service for inventory
async function createStore(body) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post(`/organizations/stores/`, body)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function getAllStores(selectElement = null, searchParam = "") {
	let url = "/organizations/stores?status=A" + searchParam;
	if (selectElement) {
		url = `${url}&select=${selectElement}`;
	}
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(url)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function getAStore(store_id, selectElement = null) {
	let url = `/organizations/stores/${store_id}`;
	if (selectElement) {
		url = `${url}?select=${selectElement}`;
	}
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(url)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function updateStore(store_id, unitPayload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/organizations/stores/${store_id}`, unitPayload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function deleteStore(store_id) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.delete(`/organizations/stores/${store_id}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

//this function is used to make a store as primary store
async function makeAsPrimaryStore(store_id) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/organizations/stores/${store_id}/make_primary`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

/**** For item attribute  */
async function createItemAttribute(body, type) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.post(`/organizations/item_attribute/${type}`, body)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function getAllItemAttribute(selectElement = null, searchParam = "") {
	let url = "/organizations/item_attribute?status=A&model=item_attribute" + searchParam;
	if (selectElement) {
		url = `${url}&select=${selectElement}`;
	}
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(url)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function updateItemAttribute(edit_id, authPayload, type) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(`/organizations/item_attribute/${type}/${edit_id}`, authPayload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function deleteItemAttribute(delete_id, type) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.delete(`/organizations/item_attribute/${type}/${delete_id}`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

async function enableInventory(inventory_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/organizations/features/inventory/enable`, inventory_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
async function changeInventoryDate(inventory_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/organizations/features/inventory/change_start_date`, inventory_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
async function changeInventoryOPAdjustAccount(inventory_payload) {
	return new Promise((resolve, reject) => {
		secureAPI()
			.patch(`/organizations/features/inventory/change_op_adjust_account`, inventory_payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data); //return single object
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}


async function getAllAdjustmentReasons() {
	const url = `/organizations/adjustment_reasons`;
	return new Promise((resolve, reject) => {
		secureAPI()
			.get(url)
			.then(({ data }) => {
				if (data.success) {
					resolve(data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

// create adjustment reason
async function createAdjustmentReason(payload) {
	const url = `/organizations/adjustment_reasons`;
	return new Promise((resolve, reject) => {
		secureAPI()
			.post(url,payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data?.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

// update adjustment reason
async function updateAdjustmentReason(id,payload) {
	const url = `/organizations/adjustment_reasons/${id}`;
	return new Promise((resolve, reject) => {
		secureAPI()
			.put(url,payload)
			.then(({ data }) => {
				if (data.success) {
					resolve(data?.data); //return array
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
// delete adjustment reason
async function deleteAdjustmentReason(id) {
	const url = `/organizations/adjustment_reasons/${id}`;
	return new Promise((resolve, reject) => {
		secureAPI()
			.delete(url)
			.then(({ data }) => {	

				if (data.success) {
					resolve(data?.data); //return array
				}
			})
			.catch((err) => {
				console.error('data in service.....', err);
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}

//organization has any contact with transaction number
/**
 * @method: GET
 * @param {*} selectElement
 * @param {*} searchParam
 * @returns
 */
async function isContactsHasAnyTransactionNumber() {

	return new Promise((resolve, reject) => {
		secureAPI()
			.get(`/organizations/is_contacts_has_transaction_number`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}
//organization has set contact transation number
/**
 * @method: GET
 * @param {*} selectElement
 * @param {*} searchParam
 * @returns
 */
async function generateAutomaticContactTransactionNumber() {

	return new Promise((resolve, reject) => {
		secureAPI()
			.post(`/organizations/generate_automatic_contact_transaction_number`)
			.then(({ data }) => {
				if (data.success) {
					resolve(data.data);
				}
			})
			.catch((err) => {
				checkTokenValidate(err);
				reject(err.response.data.error);
			});
	});
}


export {
	createNewOrganization,
	getAllOrganization,
	getAnOrganization,
	updateAOrganization,
	patchOrganization,
	deleteAnOrganization,
	//common type
	getAllCommonItems,
	getAnCommonItems,
	createCommonItems,
	deleteCommonItems,
	updateCommonItems,

	//currency
	getExchangeRates,
	createExchangeRates,
	deleteExchangeRates,
	fetchExchangeRateBasedOnDate,
	//transaction
	getOrgTransactionHistory,
	//for transaction lock
	addUpdateOrgTransactionLock,
	getTransactionLock,

	// for tax return settings
	getTaxReturnSettings,
	updateTaxReturnSettings,

	//Currency
	getAllCurrency,
	createCurrency,
	updateCurrency,
	deleteCurrency,

	// payment accounts
	setPaymentAccounts,
	getPaymentAccounts,
	removePaymentAccount,

	//update organization config
	setOrganizationConfig,
	//for unit
	createUnit,
	getAllUnits,
	getAnUnit,
	updateUnit,
	deleteUnit,
	//for Store
	createStore,
	getAllStores,
	getAStore,
	updateStore,
	deleteStore,
	makeAsPrimaryStore,

	//for item attribute
	createItemAttribute,
	getAllItemAttribute,
	updateItemAttribute,
	deleteItemAttribute,
	enableInventory,
	changeInventoryDate,
	changeInventoryOPAdjustAccount,


	// adjustment reasons
	getAllAdjustmentReasons,
	createAdjustmentReason,
	updateAdjustmentReason,
	deleteAdjustmentReason,
	updateContactSequenceNumberFormat,
	getContactSequenceNumber,
	isContactsHasAnyTransactionNumber,
	generateAutomaticContactTransactionNumber,	
};
