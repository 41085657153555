import React, { useState, useEffect, useMemo } from "react";
import { Row, Col } from "reactstrap";
import { Form, Modal } from "react-bootstrap";
import Select from "react-select";
import "../translations/i18n";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PopupSearchForm from "./searchForms/PopupSearchForm";
import { isEmpty } from "../util/helper";

//constuct seach/sort url/params here
//send url/params to parent
//this will be pushed to history to reload the page with matched data
export default function SearchBox({
	handleSrchClose,
	search_model, //seach model can be item, invoice, customer, vendor-credit,documents
	srchshow,
	queryParams = false,
}) {

	const { t } = useTranslation();
	//query parmaters from url
	//set current search model (invoices,credit-notes,customers) etc
	const [model, setModel] = useState(search_model);
	//hold different search model/form
	// const [searchForm, setSearchForm] = useState();
	const [searchFormFields, setSearchFormFields] = useState();
	// const [modelChanged, setModelChanged] = useState(false);
	//search option to show in react select
	const permission = useSelector((state) => state?.auth?.userInfo?.role_and_permission?.permission_group);
	const prime_organization_id = process.env.REACT_APP_PRIME_ORG_ID;
	const organization_id = useSelector((state) => state.auth.userInfo.default_organization_id._id);
	const salePermission = permission?.sales;
	const itemPermisson = permission?.items;
	const purchasePermission = permission?.purchases;
	const accountPermission = permission?.accountings;
	const documentsPermission = permission?.documents;

	const searchOptions = [];
	//prime firm organization search
	if (prime_organization_id === organization_id) {
		searchOptions.push({ label: t("Organization"), value: "organizations" }, { label: t("Signup request"), value: "signup-request" });
	} else {
		//for all other organization search modal
		if (true === itemPermisson?.items_view) {
			searchOptions.push({ label: t("Items"), value: "items" });
		}
		if (true === salePermission?.customers_view) {
			searchOptions.push({ label: t("Customers"), value: "customers" });
		}

		//vendor
		if (true === purchasePermission?.vendors_view) {
			searchOptions.push({ label: t("Vendors"), value: "vendors" });
		}

		if (true === salePermission?.invoices_view) {
			searchOptions.push({ label: t("Invoices"), value: "invoices" });
		}

		//bills
		if (true === purchasePermission?.bills_view) {
			searchOptions.push({ label: t("Bills"), value: "bills" });
		}

		if (true === salePermission?.proforma_invoices_view) {
			searchOptions.push({
				label: t("Proforma invoice"),
				value: "proforma_invoices",
			});
		}
		if (true === salePermission?.credit_notes_view) {
			searchOptions.push({ label: t("Credit notes"), value: "credit-notes" });
		}

		//vendor credits
		if (true === purchasePermission?.vendor_credits_view) {
			searchOptions.push({ label: t("Vendor credits"), value: "vendorcredits" });
		}

		if (true === salePermission?.collections_view) {
			searchOptions.push({ label: t("Collections"), value: "collections" });
		}

		//payments
		if (true === purchasePermission?.payments_view) {
			searchOptions.push({ label: t("Payments"), value: "payments" });
		}
		//expenses
		if (true === purchasePermission?.expenses_view) {
			searchOptions.push({ label: t("Expenses"), value: "expenses" });
		}
		//chart of account
		if (true === accountPermission?.chart_of_accounts_view) {
			searchOptions.push({ label: t("Chart of accounts"), value: "chartofaccounts" });
		}
		//manual journal
		if (true === accountPermission?.manual_journals_view) {
			searchOptions.push({ label: t("Manual journals"), value: "journal" });
		}
		if (true === salePermission?.sales_orders_view) {
			searchOptions.push({
				label: t("Sales order"),
				value: "salesorders",
			});
		}
		if (true === purchasePermission?.purchase_orders_view) {
			searchOptions.push({
				label: t("Purchase order"),
				value: "purchaseorders",
			});
		}
		if (true === documentsPermission?.documents_view) {
			searchOptions.push({
				label: t("Documents"),
				value: "documents",
			});
		}
		// if (true === salePermission?.invoices_view) {
		// 	searchOptions.push({
		// 		label: t("Recurring invoices"),
		// 		value: "recurring-invoices",
		// 	});
		// }
		// if (true === purchasePermission?.bills_view) {
		// 	searchOptions.push({
		// 		label: t("Recurring bills"),
		// 		value: "recurring-bills",
		// 	});
		// }
		// if (true === purchasePermission?.expenses_view) {
		// 	searchOptions.push({
		// 		label: t("Recurring expenses"),
		// 		value: "recurring-expenses",
		// 	});
		// }
		// if (true === accountPermission?.manual_journals_view) {
		// 	searchOptions.push({
		// 		label: t("Recurring journals"),
		// 		value: "recurring-journals",
		// 	});
		// }

		if (true === itemPermisson?.transfer_order_view) {
			searchOptions.push({
				label: t("Transfer orders"),
				value: "transfer-orders",
			});
		}
		if (true === itemPermisson?.inventory_adjustment_view) {
			searchOptions.push({
				label: t("Adjustments"),
				value: "adjustments",
			});
		}
	}

	//when model changes show different search form, so we need to build field array for each model
	let searchFormFieldsArray = {};
	searchFormFieldsArray = {
		number: {
			label: "Number",
			value: "transaction_number",
		},

		date_range: {
			label: "Date range",
			value: "issue_date_from",
			value2: "issue_date_to",
		},
		created_date_range: {
			label: "Created date range",
			value: "created_date_from",
			value2: "created_date_to",
		},
		payment_status: {
			label: "Payment status",
			value: "payment_status",
		},
		status: {
			label: "Status",
			value: "transaction_status",
		},
		item_name: {
			label: "Item name",
			value: "item_name",
		},
		total_range: {
			label: "Amount range",
			value: "net_amount_from",
			value2: "net_amount_to",
		},
		contact_name: {
			label: "Customer name",
			value: "contact_name",
		},
	};
	useEffect(() => {
		let updateFieldsArray = {};
		let returnedFinalObj;

		switch (model) {
			case "invoices":
				updateFieldsArray = {
					reference: {
						label: "Reference",
						value: "order_number",
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "bills":
				updateFieldsArray = {
					contact_name: {
						label: "Vendor name",
						value: "contact_name",
					},
					reference: {
						label: "Reference",
						value: "order_number",
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "proforma_invoices":
				updateFieldsArray = {
					reference: {
						label: "Reference",
						value: "order_number",
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "credit-notes":
				//if any changes in model, update the search form fields
				updateFieldsArray = {
					reference: {
						label: "Reference",
						value: "reference",
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "vendorcredits":
				updateFieldsArray = {
					contact_name: {
						label: "Vendor name",
						value: "contact_name",
					},
					reference: {
						label: "Reference",
						value: "reference",
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "items":
				updateFieldsArray = {
					item_input: {
						label: "Item name",
						value: "item_name",
					},
					item_type: {
						label: "Item type",
						value: "item_type",
					},
					total_range: {
						label: "Price range",
						value: "net_amount_from",
						value2: "net_amount_to",
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "payments":
				updateFieldsArray = {
					contact_name: {
						label: "Vendor name",
						value: "contact_name",
					},
					reference: {
						label: "Reference",
						value: "reference_number",
					},
					credits: {
						label: "Balance range",
						value: "balance_from",
						value2: "balance_to",
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "collections":
				updateFieldsArray = {
					reference: {
						label: "Reference",
						value: "reference_number",
					},
					credits: {
						label: "Balance range",
						value: "balance_from",
						value2: "balance_to",
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "customers":
				updateFieldsArray = {
					contact_name: {
						label: "Customer name",
						value: "contact_name",
					},
					contact_email: {
						label: "Email",
						value: "email",
					},
					customer_type: {
						label: "Customer type",
						value: "customer_type",
					},
					outstanding_range: {
						label: "Outstanding receivables",
						value: "outstanding_receivables_from",
						value2: "outstanding_receivables_to",
					},
					credits: {
						label: "Unused credits",
						value: "balance_from",
						value2: "balance_to",
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "vendors":
				updateFieldsArray = {
					contact_name: {
						label: "Vendor name",
						value: "contact_name",
					},
					contact_email: {
						label: "Email",
						value: "email",
					},
					outstanding_range: {
						label: "Outstanding payables",
						value: "outstanding_payable_from",
						value2: "outstanding_payable_to",
					},
					credits: {
						label: "Unused credits",
						value: "balance_from",
						value2: "balance_to",
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "chartofaccounts":
				updateFieldsArray = {
					account_name: {
						label: "Account name",
						value: "account_name",
					},
					account_code: {
						label: "Account code",
						value: "account_code",
					},
					account_group: {
						label: "Account group",
						value: "account_group",
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "journal":
				updateFieldsArray = {
					contact_name: {
						label: t("Customer") + "/" + t("Vendor"),
						value: "contact_name",
					},
					reference: {
						label: "Reference",
						value: "reference_number",
					},
					account_name: {
						label: "Account name",
						value: "account_name",
					},
				};

				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "expenses":
				updateFieldsArray = {
					contact_name: {
						label: t("Customer"),
						value: "contact_name",
					},

					vendor_name: {
						label: t("Vendor"),
						value: "vendor_name",
					},

					reference: {
						label: "Reference",
						value: "reference",
					},

					expenses_account_name: {
						label: "Expense account",
						value: "expenses_account_name",
					},

					paid_through_account_name: {
						label: "Paid through",
						value: "paid_through_account_name",
					},
				};

				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "salesorders":
				updateFieldsArray = {
					reference: {
						label: "Reference",
						value: "order_number",
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "purchaseorders":
				updateFieldsArray = {
					reference: {
						label: "Reference",
						value: "order_number",
					},
					contact_name: {
						label: "Vendor",
						value: "contact_name",
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);

				break;
			case "documents":
				updateFieldsArray = {
					number: {
						label: "File name",
						value: "file_name",
					},
					reference: {
						label: "Document type",
						value: "document_type",
					},
					created_date_range: {
						label: "Uploaded date range",
						value: "created_date_from",
						value2: "created_date_to",
					},
					contact_name: {
						label: "User name",
						value: "user_id",
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "recurring-bills":
			case "recurring-invoices":
				updateFieldsArray = {
					profile_name: {
						label: "Profile name",
						value: "profile_name",
					},
					frequency: {
						label: "Frequency",
						value: "frequency",
					},
					contact_name: {
						label: "recurring-invoices" === model ? "Customer" : "Vendor",
						value: "contact_name",
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "recurring-expenses":
				updateFieldsArray = {
					profile_name: {
						label: "Profile name",
						value: "profile_name",
					},
					frequency: {
						label: "Frequency",
						value: "frequency",
					},
					contact_name: {
						label: t("Customer"),
						value: "contact_name",
					},

					vendor_name: {
						label: t("Vendor"),
						value: "vendor_name",
					},

					reference: {
						label: "Reference",
						value: "reference",
					},

					expenses_account_name: {
						label: "Expense account",
						value: "expenses_account_name",
					},

					paid_through_account_name: {
						label: "Paid through",
						value: "paid_through_account_name",
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "recurring-journals":
				updateFieldsArray = {
					profile_name: {
						label: "Profile name",
						value: "profile_name",
					},
					frequency: {
						label: "Frequency",
						value: "frequency",
					},
					contact_name: {
						label: t("Customer") + "/" + t("Vendor"),
						value: "contact_name",
					},
					reference: {
						label: "Reference",
						value: "reference_number",
					},
					account_name: {
						label: "Account name",
						value: "account_name",
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "transfer-orders":
				updateFieldsArray = {					
					reason: {
						label: "Reason",
						value: "reason",
					},
					source_ware_house: {
						label: "Source store",
						value: "from_store_id",
					},
					destination_ware_house: {
						label: "Destination store",
						value: "to_store_id",
					},					
					transfer_date_range : {
						label : 'Transfer date range',
						value : "transfer_date_from",
						value2 : "transfer_date_to"
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			case "adjustments":
					updateFieldsArray = {	
										
						reason: {
							label: "Reason",
							value: "reason",
						},
						source_ware_house: {
							label: "Store",
							value: "from_store_id",
						},						
						adjustment_type : {
							label : 'Adjustment type',
							value : "adjustment_type"
						},
						adjustment_date_range : {
							label : 'Adjustment date range',
							value : "adjustment_date_from",
							value2 : "adjustment_date_to"
						},
						branch :{
							label : 'Branch',
							value : 'branch_id'
						}
					};
					returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
					setSearchFormFields(returnedFinalObj);
				break;
			case "signup-request":
				updateFieldsArray = {
					reason: {
						label: "Reason",
						value: "reason",
					},
				};
				returnedFinalObj = Object.assign(searchFormFieldsArray, updateFieldsArray);
				setSearchFormFields(returnedFinalObj);
				break;
			default:
				break;
		}
	}, [model]);

	// on dropdown this value will be shown
	const selectedOption = useMemo(() => {
		let s;
		const v = searchOptions?.find((op) => op.value === model);
		if (isEmpty(v)) {
			s = searchOptions[0];
			setModel(s.value);
		} else {
			s = v;
		}
		return s;
	}, [searchOptions, model]);


	return (
		<Modal show={srchshow} className="srchcrtepop" onHide={handleSrchClose}>
			{"true" === process.env.REACT_APP_BASE_SHOW_PATH_HINTS && <p style={{ color: "red" }}>src/common/SearchBox.js</p>}
			<Form>
				<Modal.Header closeButton style={{ backgroundColor: "#efefef" }}>
					<Row className="invosrch">
						<Form.Label column sm={3}>
							{t("Search")}
						</Form.Label>
						<Col>
							<Select
								placeholder={t("Select")}
								onChange={(item) => {
									setModel(item.value);
								}}
								value={{
									label: selectedOption.label,
									value: model,
								}}
								options={searchOptions}
							/>
						</Col>
					</Row>
				</Modal.Header>
				{/* {searchForm} */}

				<PopupSearchForm
					handleSrchClose={handleSrchClose}
					search_model={model} //model is the selected search model
					fieldArray={searchFormFields}
				/>
			</Form>
		</Modal>
	);
}
